import React from "react";
import s from "../styles/ScheduleModal.module.css";
import s2 from "../styles/general/icons.module.css"

const ScheduleModal = ({ schedule = [], setModalOn }) => {
  return (
    <div className={s.ScheduleModalBackdrop}>
      <div className={s.ScheduleModalContent}>
        <button
          className={`${s2.content19} ${s.ScheduleModalCloseButton}`}
          onClick={() => {
            setModalOn(false);
            document.getElementsByTagName("body")[0].style.overflow = "auto";
          }}
        >
        </button>
        <div>
          <table className={s.ScheduleModalTable}>
            <tbody>
              <tr>
                <th className={s.ScheduleModalHeader}>День</th>
                <th className={s.ScheduleModalHeader}>Години<br />роботи</th>
                <th className={s.ScheduleModalHeader}>Перерва</th>
              </tr>

              <tr>
                <td className={s.ScheduleModalData}>Понеділок</td>
                <td className={s.ScheduleModalData}>{schedule?.Monday}</td>
                <td className={s.ScheduleModalData}></td>
              </tr>

              <tr>
                <td className={s.ScheduleModalData}>Вівторок</td>
                <td className={s.ScheduleModalData}>{schedule?.Tuesday}</td>
                <td className={s.ScheduleModalData}></td>
              </tr>

              <tr>
                <td className={s.ScheduleModalData}>Середа</td>
                <td className={s.ScheduleModalData}>{schedule?.Wednesday}</td>
                <td className={s.ScheduleModalData}></td>
              </tr>

              <tr>
                <td className={s.ScheduleModalData}>Четвер</td>
                <td className={s.ScheduleModalData}>{schedule?.Thursday}</td>
                <td className={s.ScheduleModalData}></td>
              </tr>

              <tr>
                <td className={s.ScheduleModalData}>Пʼятниця</td>
                <td className={s.ScheduleModalData}>{schedule?.Friday}</td>
                <td className={s.ScheduleModalData}></td>
              </tr>

              <tr>
                <td className={s.ScheduleModalData}>Субота</td>
                <td className={s.ScheduleModalData}>{schedule?.Saturday}</td>
                <td className={s.ScheduleModalData}></td>
              </tr>

              <tr>
                <td className={s.ScheduleModalData}>Неділя</td>
                <td className={s.ScheduleModalData}>{schedule?.Sunday}</td>
                <td className={s.ScheduleModalData}></td>
              </tr>
            </tbody>
          </table>
          <p className={s.ScheduleModalFooter}>
            * Час вказано для регіону: Україна, Київ
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScheduleModal;
