import { Route, Routes, useNavigate } from "react-router-dom";
import MenuAdmin from "./components/admin/menu_admin";
import { useEffect } from "react";
import CategoriesAdmin from "./components/admin/categories_admin";
import ProductsAdmin from "./components/admin/products_admin";
import ProductDetailsAdmin from "./components/admin/product_details_admin";
import EditProductPreview from "./components/admin/edit_product_preview";
import { useState } from "react";
import Login from "./components/admin/login";
import { getCookieValue } from "./App";
import { adminAPI } from "./api/api";
import FeedbacksPageAdmin from "./components/admin/feedback_admin";
import Info from "./components/admin/info";
import OrdersAdmin from "./components/admin/orders_admin";

import styles from "./styles/Admin.module.css";

const Admin = () => {
  const [filter, setFilter] = useState("");
  const [filterValue, setFilterValue] = useState("true");
  const [inputValue, setInputValue] = useState();
  const [searchOption, setSearchOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLogged, setIsLogged] = useState(false);

  const validateToken = async () => {
    try {
      await adminAPI.validateToken();
      setIsLogged(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (getCookieValue("token")) validateToken();
    
    document.getElementsByTagName("body")[0].classList.add(styles.bodyWithoutBackground);
    const htmlElement = document.documentElement; 
    htmlElement.style.minWidth = `1536px`;
    
    return () => {
      document.getElementsByTagName("body")[0].classList.remove(styles.bodyWithoutBackground);
      document.getElementsByTagName("body")[0].classList.add(styles.bodyWithBackground);
      htmlElement.style.minWidth = '';
    };
  }, []);

  if (!isLogged) return <Login setIsLogged={setIsLogged}></Login>;

  return (
    <>
      <MenuAdmin setIsLogged={setIsLogged}></MenuAdmin>
      <Routes>
        <Route path="/" element={<Redirect to="products"></Redirect>}></Route>
        <Route path="/categories" element={<CategoriesAdmin></CategoriesAdmin>}></Route>
        <Route path="/subcategories/:any" element={<CategoriesAdmin></CategoriesAdmin>}></Route>
        <Route
          path="/products"
          element={
            <ProductsAdmin
              filter={filter}
              filterValue={filterValue}
              inputValue={inputValue}
              currentPage={currentPage}
              searchOption={searchOption}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              setInputValue={setInputValue}
              setCurrentPage={setCurrentPage}
              setSearchOption={setSearchOption}
            ></ProductsAdmin>
          }
        ></Route>
        <Route path="/products/edit/:any" element={<EditProductPreview></EditProductPreview>}></Route>
        <Route path="/product/:any" element={<ProductDetailsAdmin></ProductDetailsAdmin>}></Route>
        <Route path="/feedback" element={<FeedbacksPageAdmin></FeedbacksPageAdmin>}></Route>
        <Route path="/orders" element={<OrdersAdmin></OrdersAdmin>}></Route>
        <Route path="/info" element={<Info></Info>}></Route>
      </Routes>
    </>
  );
};

const Redirect = ({ to = "products" }) => {
  useRedirect(to);
};

const useRedirect = (to = "products") => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, [navigate, to]);
};

export default Admin;