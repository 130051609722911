import Caption from "../caption";
import s from "../../styles/general/icons.module.css";
import { useEffect, useState } from "react";
import { adminAPI } from "../../api/api";

const Contacts2Admin = ({
  contacts = {
    company: "",
    contactPerson: "",
    phone: "",
    site: "",
    email: "",
    telegram: "",
    viber: "",
    address: "",
  },
  editOn = false,
  setEditOn = () => {},
  getInfo = () => {},
}) => {
  const [Contacts, setContacts] = useState(contacts);
  useEffect(() => {
    setContacts(contacts);
  }, [contacts]);
  if (editOn)
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Компанія:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, company: e.target.value })
              }
              value={Contacts?.company}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Контактна особа:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, contactPerson: e.target.value })
              }
              value={Contacts?.contactPerson}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Телефон:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, phone: e.target.value })
              }
              value={Contacts?.phone}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Сайт:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, site: e.target.value })
              }
              value={Contacts?.site}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Email:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, email: e.target.value })
              }
              value={Contacts?.email}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Telegram:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, telegram: e.target.value })
              }
              value={Contacts?.telegram}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Viber:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, viber: e.target.value })
              }
              value={Contacts?.viber}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Адреса:</label>
            <input
              onChange={(e) =>
                setContacts({ ...Contacts, address: e.target.value })
              }
              value={Contacts?.address}
            ></input>
          </div>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <button
            onClick={async () => {
              await adminAPI.editInfo({
                contacts: Contacts,
              });
              getInfo();
              setEditOn(false);
            }}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Зберегти
          </button>
          <button
            onClick={() => setEditOn(false)}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Скасувати
          </button>
        </div>
      </div>
    );
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Caption text={"Контакти"}></Caption>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ width: "300px", padding: "10px 0" }}>Компанія:</span>
          <span>{contacts?.company}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid #C1C1C1",
          }}
        >
          <span style={{ width: "300px", padding: "10px 0" }}>
            Контактна особа
          </span>
          <span>{contacts?.contactPerson}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid #C1C1C1",
          }}
        >
          <span style={{ width: "300px", padding: "10px 0" }}>Телефон</span>
          <span>
            {contacts?.phone?.slice(0, 4) +
              " (" +
              contacts?.phone?.slice(4, 6) +
              ") " +
              contacts?.phone?.slice(6, 9) +
              "-" +
              contacts?.phone?.slice(9, 11) +
              "-" +
              contacts?.phone?.slice(11, 13)}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid #C1C1C1",
          }}
        >
          <span style={{ width: "300px", padding: "10px 0" }}>Сайт</span>
          <span
            className={s.content12}
            style={{
              color: "#f5643e",
            }}
          >
            {contacts?.site}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid #C1C1C1",
          }}
        >
          <span style={{ width: "300px", padding: "10px 0" }}>Email</span>
          <span
            className={s.content12}
            style={{
              color: "#f5643e",
            }}
          >
            {contacts?.email}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid #C1C1C1",
          }}
        >
          <span style={{ width: "300px", padding: "10px 0" }}>Telegram</span>
          <span>{contacts?.telegram}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid #C1C1C1",
          }}
        >
          <span style={{ width: "300px", padding: "10px 0" }}>Viber</span>
          <span>{contacts?.viber}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderTop: "1px solid #C1C1C1",
          }}
        >
          <span style={{ width: "300px", padding: "10px 0" }}>Адреса</span>
          <span style={{ fontStyle: "italic" }}>{contacts?.address}</span>
        </div>
      </div>
    </div>
  );
};

export default Contacts2Admin;
