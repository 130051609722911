import React from "react";
import s from "../styles/Schedule.module.css";

const Schedule = ({ schedule = {} }) => {
  return (
    <div className={s.scheduleContainer}>
      <div className={s.scheduleHeader}>
        <h6 className={s.scheduleTitle}>
          <span className={s.scheduleTitleHighlight}>Г</span>рафік роботи
        </h6>
      </div>
      <ul className={s.scheduleList}>
        <li className={s.scheduleItem}>
          <span className={s.scheduleDay}>Понеділок</span>
          <span>{schedule?.Monday}</span>
        </li>
        <li className={s.scheduleItem}>
          <span className={s.scheduleDay}>Вівторок</span>
          <span>{schedule?.Tuesday}</span>
        </li>
        <li className={s.scheduleItem}>
          <span className={s.scheduleDay}>Середа</span>
          <span>{schedule?.Wednesday}</span>
        </li>
        <li className={s.scheduleItem}>
          <span className={s.scheduleDay}>Четвер</span>
          <span>{schedule?.Thursday}</span>
        </li>
        <li className={s.scheduleItem}>
          <span className={s.scheduleDay}>П'ятниця</span>
          <span>{schedule?.Friday}</span>
        </li>
        <li className={s.scheduleItem}>
          <span className={s.scheduleDay}>Субота</span>
          <span>{schedule?.Saturday}</span>
        </li>
        <li className={s.scheduleItem}>
          <span className={s.scheduleDay}>Неділя</span>
          <span>{schedule?.Sunday}</span>
        </li>
      </ul>
    </div>
  );
};

export default Schedule;
