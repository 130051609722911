import React, { useEffect, useState } from "react";
import s from "../styles/general/icons.module.css";
import styles from "../styles/FeedbacksPage.module.css";
import { publicAPI } from "../api/api";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";
import { useLocation } from "react-router-dom";
import Pagination from "./pagination";

const FeedbacksPage = ({
  setReccomendationsOn = () => {},
  setFeedbackOnSidebar = () => {},
  sidebar = false,
}) => {
  setReccomendationsOn(false);
  const navigate = useConditionalNavigate();
  const location = useLocation();
  if (location.pathname === "/feedback") setFeedbackOnSidebar(false);
  const [comments, setComments] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const getComments = async () => {
    try {
      const res = await publicAPI.getComments(
        `pageSize=${pageSize}&currentPage=${currentPage}`
      );
      setComments(res.data.comments);
      setTotalCount(res.data.totalCount);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getComments();
    return () => {
      setFeedbackOnSidebar(true);
    };
  }, [currentPage]);

  return (
    <div>
      {!sidebar && (
        <Pagination
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      {comments.length > 0 && (
        <div className={styles.commentsContainer}>
          {comments.map((comment) => (
            <Feedback {...comment} sidebar={sidebar} key={comment._id} />
          ))}
        </div>
      )}
      {sidebar && comments.length > 0 && (
        <div className={styles.sidebarButtons}>
          <button
            onClick={(e) => {
              navigate("/feedback", e);
              window.scrollTo(0, 0);
            }}
            className={styles.feedbackButton}
          >
            Додати відгук
          </button>
          <button
            onClick={(e) => {
              navigate("/feedback", e);
              window.scrollTo(0, 0);
            }}
            className={styles.feedbackButton}
          >
            Всі відгуки
          </button>
        </div>
      )}
    </div>
  );
};

const Feedback = ({
  customerName = "",
  starCount = 0,
  date = "",
  date2 = "",
  feedback = "",
  vendorComment = "",
  sidebar = false,
}) => {
  const starIndexes = [1, 2, 3, 4, 5];
  return (
    <div className={styles.feedbackContainer}>
      <div className={`${styles.feedbackHeader} ${sidebar ? styles.column : styles.row}`}>
        <div className={styles.customerInfo}>
          <span className={s.content21}></span>
          <span className={styles.customerName}>{customerName}</span>
        </div>
        <div className={styles.rating}>
          <span>
            {starCount === 0
              ? "Жахливо"
              : starCount === 1
              ? "Погано"
              : starCount === 2
              ? "Недуже"
              : starCount === 3
              ? "Добре"
              : starCount === 4
              ? "Відмінно"
              : "Бездоганно"}
          </span>
          <div className={styles.stars}>
            {starIndexes.map((i) => (
              <StarIcon fill={i <= starCount ? "yellow" : "white"} key={i} />
            ))}
          </div>
        </div>
        <div className={styles.dateInfo}>
          <span className={s.content22}></span>
          <span>{date}</span>
        </div>
      </div>
      <div className={styles.feedbackContent}>
        <div className={sidebar ? styles.fullWidth : styles.halfWidth}>
          <span>{feedback}</span>
        </div>
        {date2 && vendorComment && !sidebar && (
          <div className={styles.vendorComment}>
            <div className={styles.vendorHeader}>
              <span className={styles.vendorTitle}>Коментар продавця</span>
              <div className={styles.vendorDate}>
                <span className={s.content22}></span>
                <span>{date2}</span>
              </div>
            </div>
            <span>{vendorComment}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const StarIcon = ({ fill = "white" }) => {
  return (
    <svg
      width="18px"
      height="18px"
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="star-icon"
    >
      <path
        fill={fill}
        stroke="black"
        strokeWidth="2"
        d="M11.985,18.136 L5.187,23.000 L7.536,14.988 L1.000,9.562 L8.950,9.493 L11.985,1.000 L14.936,9.562 L23.000,9.562 L16.433,15.040 L18.783,23.000 L11.985,18.136 L11.985,18.136 Z"
      />
    </svg>
  );
};

export default FeedbacksPage;
