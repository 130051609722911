import { useEffect, useRef, useState } from "react";
import { adminAPI } from "../../api/api";
import CartAdmin from "./cart_admin";
import FilterAdmin from "./filter_admin";
import SearchInputAdmin from "./search_products_admin";
import Pagination from "../pagination";
import InvoicePDF from "./invoice_pdf";
import { useReactToPrint } from "react-to-print";

const OrdersAdmin = () => {
  const [orders, setOrders] = useState([]);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [filter, setFilter] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null);
  const getOrders = async () => {
    const res = await adminAPI.getOrders(
      `pageSize=${pageSize}&currentPage=${currentPage}&orderNumber=${inputValue}&filter=${filter}`
    );
    setOrders(res?.data?.orders);
    setTotalCount(res?.data?.totalCount);
  };
  useEffect(() => {
    const asyncSearch = async () => {
      clearTimeout(debounceTimer);
      setDebounceTimer(
        setTimeout(async () => {
          getOrders();
        }, 500)
      );
    };
    asyncSearch();
  }, [currentPage, inputValue, filter]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "  20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <SearchInputAdmin
            inputValue={inputValue}
            setInputValue={setInputValue}
            setCurrentPage={setCurrentPage}
          ></SearchInputAdmin>
          {/* <SearchOptionsAdmin
            setSearchOption={setSearchOption}
            setCurrentPage={setCurrentPage}
            selected={searchOption}
          ></SearchOptionsAdmin> */}
          <FilterAdmin
            setFilter={setFilter}
            selected={filter}
            setCurrentPage={setCurrentPage}
            options={[
              { text: "Всі", selected: "" },
              { text: "Нове замовлення", selected: "Нове замовлення" },
              { text: "Прийнято", selected: "Прийнято" },
              { text: "Виконано", selected: "Виконано" },
              { text: "Скасовано", selected: "Скасовано" },
              { text: "Оплачено", selected: "Оплачено" },
              { text: "Очікується оплата", selected: "Очікується оплата" },
              {
                text: "Відслідковується накладна",
                selected: "Відслідковується накладна",
              },
              { text: "Архівовано", selected: "Архівовано" },
            ]}
          ></FilterAdmin>
        </div>
        <Pagination
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        ></Pagination>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr",
          gap: "20px",
          padding: "10px",
          marginBottom: "20px",
          borderBottom: "0.5px solid #e8e8e8",
          borderTop: "0.5px solid #e8e8e8",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Замовлення</span>
        <span style={{ fontWeight: "bold" }}>Сума</span>
        <span style={{ fontWeight: "bold" }}>Покупець</span>
        <span style={{ fontWeight: "bold" }}>Доставка і оплата</span>
        <span style={{ fontWeight: "bold" }}>Статус</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        {orders.length > 0 &&
          orders.map((order) => <Order order={order}></Order>)}
      </div>
    </div>
  );
};

const Order = ({ order = {} }) => {
  const [Order, setOrder] = useState(order);
  const [cart, setCart] = useState(order?.details?.cart);
  const [isChanges, setIsChanges] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const contentRef = useRef(null);
  const reactToPrintFn = useReactToPrint({
    contentRef,
    pageStyle: `
    @page {
      size: A4;
      margin: 0;
    }
    body {
      transform: scale(1.0);
      transform-origin: top left;
      margin: 5mm;
      width: 210mm; 
      box-sizing: border-box;
    }
  `,
  });

  const getOrder = async () => {
    try {
      const res = await adminAPI.getOrders(`_id=${Order?._id}`);
      setOrder(res?.data?.orders[0]);
      setCart(res?.data?.orders[0].details?.cart);
      setIsChanges(false);
      setCartOpen(false);
    } catch (err) {
      console.log(err);
    }
  };
  const editOrder = async () => {
    try {
      const newOrder = { ...Order };
      newOrder.details.cart = cart;
      await adminAPI.editOrder(Order?._id, newOrder);
      await getOrder();
    } catch (err) {
      console.log(err);
    }
  };
  const isPeriodValid = (discountPeriod) => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };
  useEffect(() => {
    const newOrder = { ...Order };
    newOrder.details.sum = cart.reduce((acum, obj) => {
      let price;
      let entities = obj.product.wholesalePrices.filter(
        (entity) => entity.count <= obj.count
      );
      if (entities.length > 0) price = entities[entities.length - 1].price;
      else
        price =
          obj.product?.discountPrice &&
          isPeriodValid(obj.product?.discountPeriod)
            ? obj.product.discountPrice
            : obj.product.price;
      return acum + +price * obj.count;
    }, 0);
    setOrder(newOrder);
  }, [cart]);
  useEffect(() => {
    setOrder(order);
    setCart(order?.details?.cart);
    setIsChanges(false);
    setCartOpen(false);
  }, [order]);
  // return <InvoicePDF order={order} ref={contentRef}></InvoicePDF>;
  return (
    <div
      style={{
        display: "grid",
        padding: "10px",
        gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr",
        gap: "20px",
        borderBottom: "0.5px solid #e8e8e8",
        borderTop: "0.5px solid #e8e8e8",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          wordWrap: "break-word",
          gap: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <span style={{ color: "blue" }}>№{Order?.orderNumber}</span>
            <span style={{ color: "gray" }}>
              {new Date(Order?.createdAt).toLocaleDateString()}
            </span>
          </div>
          <div>
            <span
              onClick={reactToPrintFn}
              style={{ color: "blue", cursor: "pointer" }}
            >
              PDF
            </span>
            <div hidden>
              <InvoicePDF order={order} ref={contentRef}></InvoicePDF>
            </div>
          </div>
        </div>
        <CartAdmin
          cart={cart}
          setCart={setCart}
          setIsChanges={setIsChanges}
          cartOpen={cartOpen}
          setCartOpen={setCartOpen}
        ></CartAdmin>
        {isChanges && (
          <div style={{ display: "flex", gap: "20px" }}>
            <button
              onClick={() => editOrder()}
              style={{
                padding: "5px",
                margin: "10px 0",
                background: "rgb(106, 180, 22)",
                width: "fit-content",
                color: "white",
                fontSize: "16px",
                border: "none",
                borderRadius: "3px",
              }}
            >
              Зберегти
            </button>
            <button
              onClick={() => {
                getOrder();
                setIsChanges(false);
              }}
              style={{
                padding: "5px",
                margin: "10px 0",
                background: "rgb(106, 180, 22)",
                width: "fit-content",
                color: "white",
                fontSize: "16px",
                border: "none",
                borderRadius: "3px",
              }}
            >
              Скасувати
            </button>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          wordWrap: "break-word",
          gap: "10px",
        }}
      >
        <span>Сума: {Order?.details?.sum}</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          wordWrap: "break-word",
          gap: "10px",
        }}
      >
        <span>{Order?.details?.name}</span>
        <span>{Order?.details?.phone}</span>
        {Order?.details?.comment && (
          <span>Коментар: {Order?.details?.comment}</span>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          wordWrap: "break-word",
          gap: "10px",
        }}
      >
        <span>{Order?.details?.postalService}</span>
        <span>{Order?.details?.settlement}</span>
        <span>{Order?.details?.department}</span>
        <span>{Order?.paymentType}</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          wordWrap: "break-word",
          gap: "10px",
        }}
      >
        <select
          style={{ width: "fit-content" }}
          value={Order?.status}
          onChange={(e) => {
            const newOrder = { ...Order };
            newOrder.status = e.target.value;
            setOrder(newOrder);
            setIsChanges(true);
          }}
        >
          <option>Нове замовлення</option>
          <option>Прийнято</option>
          <option>Виконано</option>
          <option>Скасовано</option>
          <option>Оплачено</option>
          <option>Очікується оплата</option>
          <option>Відслідковується накладна</option>
          <option>Архівовано</option>
        </select>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {order?.details?.cart.map(entity => {
        return <div style={{display: "flex", gap: "50px"}}>
          <span style={{ flexBasis: "50%" }}>{entity?.product?.title}</span>
          <span style={{ flexBasis: "50%" }}>Кількість: {entity?.count}</span>
        </div>
      })}
      </div> */}
    </div>
  );
};

export default OrdersAdmin;
