import { adminAPI } from "../../api/api";

const Overlay = ({
  type = "Split",
  indexes = [0, 0],
  product = {},
  _id = "",
  getProduct = () => {},
}) => {
  return (
    <div
      onDrop={async (e) => {
        {
          e.preventDefault();
          const files = e.dataTransfer.files;
          const newFileName = `${Number(new Date())}_${files[0].name}`;
          const updatedFile = new File([files[0]], newFileName, {
            type: files[0].type,
          });
          if (
            /^image\/(jpeg|png|gif|webp|bmp|svg\+xml)$/.test(files[0]?.type)
          ) {
            let unlink = "";
            product.pictures[indexes[0]] = product.pictures[indexes[0]]
              .split("\n")
              .map((url, i) => {
                if (i === indexes[1]) {
                  unlink = url;
                  return `images/products/${newFileName}`;
                } else return url;
              })
              .join("\n");
            const formData = new FormData();
            product.pictures.forEach((picture, index) => {
              formData.append(`pictures[${index}]`, picture);
            });
            formData.append("unlink", unlink);
            formData.append("file", updatedFile);
            try {
              await adminAPI.editProductDetails(_id, formData);
              getProduct();
            } catch (err) {
              console.log(err);
            }
          }
        }
      }}
      onDragOver={(e) => e.preventDefault()}
      style={{
        opacity: "0",
        width: "100%",
        height: "100%",
        display: "flex",
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        border: "2px dashed white",
      }}
    >
      <button
        style={{
          height: "fit-content",
          fontSize: "16px",
          fontWeight: "600",
          backgroundColor: "transparent",
          outline: "none",
          cursor: "pointer",
          color: "white",
          backgroundColor: "unset",
          border: "2px solid white",
          borderRadius: "10px",
          padding: "8px",
        }}
        onClick={async () => {
          const unlink = product.pictures[indexes[0]].split("\n")[+!indexes[1]];
          if (type === "Split") {
            product.pictures[indexes[0]] += "\n";
          } else if (type === "Join") {
            product.pictures[indexes[0]] =
              product.pictures[indexes[0]].split("\n")[indexes[1]];
          }
          const formData = new FormData();
          product.pictures.forEach((picture, index) => {
            formData.append(`pictures[${index}]`, picture);
          });
          formData.append("unlink", unlink);
          try {
            await adminAPI.editProductDetails(_id, formData);
            getProduct();
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {type}
      </button>
      <span
        style={{
          color: "white",
          position: "absolute",
          top: "10px",
          fontSize: "18px",
        }}
      >
        Drag & Drop
      </span>
    </div>
  );
};

export default Overlay;
