import { useEffect, useState } from "react";
import styles from "../styles/Cart_modal.module.css";
import CartItem from "./cart_item";
import { publicAPI } from "../api/api";
import GetContacts from "./get_contacts";

const CartModal = ({ cart = [], setCart = () => {}, setCartOn = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isPeriodValid = (discountPeriod) => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };

  const getProducts = async () => {
    try {
      const res = await publicAPI.getProducts(
        "",
        `filter=_ids&filterValue=${cart.map((product) => product._id)}`
      );
      setCart(
        cart.filter((product) => {
          if (res.data.products.find((prod) => product._id === prod._id))
            return product;
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    getProducts();
    return () =>
      (document.getElementsByTagName("body")[0].style.overflow = "auto");
  }, []);

  return (
    <>
      {isOpen && (
        <GetContacts
          isOpen={isOpen}
          cart={cart}
          setIsOpen={setIsOpen}
          setCartOn={setCartOn}
          setCart={setCart}
        ></GetContacts>
      )}
      {!isOpen && (
        <div className={styles.overlay}>
          <div className={styles.modalContainer}>
            <div className={styles.modalContent}>
              <div className={styles.header}>
                <h1 className={styles.cartTitle}>Кошик</h1>
                <div onClick={() => setCartOn(false)} className={styles.close}>
                  <svg
                    className={styles.closeIcon}
                    width="24px"
                    height="24px"
                    fill="none"
                    viewBox="0 0 24 24"
                    data-testid="SvgClose"
                    data-qaid="SvgClose"
                    focusable="false"
                    aria-hidden="true"
                    tabIndex="-1"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M4.414 3A1 1 0 0 0 3 4.414L10.586 12 3 19.586A1 1 0 1 0 4.414 21L12 13.414 19.586 21A1 1 0 0 0 21 19.586L13.414 12 21 4.414A1 1 0 0 0 19.586 3L12 10.586 4.414 3Z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className={styles.cartItemsContainer}>
              <div>
                  {cart.length > 0 &&
                    cart.map((product) => (
                      <CartItem
                        cart={cart}
                        setCart={setCart}
                        {...product}
                      ></CartItem>
                    ))}
                </div>
              {cart.length > 0 && (
                <div className={styles.summary}>
                  <div className={styles.summaryRow}>
                    <span className={styles.summaryLabel}>
                      До сплати без доставки:
                    </span>
                    <span className={styles.summaryValue}>
                      {cart.reduce((acum, product) => {
                        let price;
                        let entities = product.wholesalePrices.filter(
                          (entity) => entity.count <= product.count
                        );
                        if (entities.length > 0)
                          price = entities[entities.length - 1].price;
                        else
                          price =
                            product?.discountPrice &&
                            isPeriodValid(product?.discountPeriod)
                              ? product.discountPrice
                              : product.price;
                        return acum + +price * product.count;
                      }, 0) + " ₴"}
                    </span>
                  </div>
                  <div className={styles.submitButtonContainer}>
                    <button
                      onClick={async () => {
                        setIsOpen(true);
                      }}
                      className={styles.submitButton}
                    >
                      Оформити замовлення
                    </button>
                  </div>
                </div>
              )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartModal;