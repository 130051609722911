const SearchOptionsAdmin = ({
  setSearchOption = () => {},
  setCurrentPage = () => {},
  selected = "",
  options = [
    { text: "Продукти", selected: "" },
    { text: "Категорії", selected: "category" },
  ],
}) => {
  return (
    <div>
      <span style={{marginRight: "10px"}}>Пошук: </span>
      <select
        onChange={(e) => {
          setSearchOption(e.target.value);
          setCurrentPage(1);
        }}
        style={{
          borderRadius: "50px",
          padding: "5px",
          fontSize: "14px",
          border: "1px solid #e8e8e8",
          outline: "none",
          boxShadow: "0 0px 2px rgba(0, 0, 0, 0.1)",
        }}
      >
        {options.map((option) => (
          <option
            selected={selected === option.selected}
            value={option.selected}
            style={{ fontSize: "14px" }}
          >
            {option.text}
          </option>
        ))}
        {/* <option
          selected={selected === ""}
          value={""}
          style={{ fontSize: "14px" }}
        >
          Продукти
        </option>
        <option
          selected={selected === "category"}
          value={"category"}
          style={{ fontSize: "14px" }}
        >
          Категорії
        </option> */}
      </select>
    </div>
  );
};

export default SearchOptionsAdmin;
