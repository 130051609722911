import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Flag from "../flag";
import s from "../../styles/general/overlay.module.css";
import Pagination from "../pagination";
import { adminAPI, publicAPI } from "../../api/api";
import HOST from "../../env";
import FilterAdmin from "./filter_admin";
import ConfirmDeleteModal from "./confirm_delete_modal";
import useConditionalNavigate from "../custom_hooks/use_conditional_navigate";
import SearchOptionsAdmin from "./search_options_admin";
import SearchInputAdmin from "./search_products_admin";

const ProductsAdmin = ({
  filter = "",
  filterValue = "",
  inputValue = "",
  currentPage = "",
  searchOption = "",
  setFilter = () => {},
  setFilterValue = () => {},
  setInputValue = () => {},
  setCurrentPage = () => {},
  setSearchOption = () => {},
}) => {
  const navigate = useConditionalNavigate();
  const [pageSize] = useState(16);
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(() => {});
  const [debounceTimer, setDebounceTimer] = useState(null);
  const getProducts = async (query = "") => {
    try {
      const res = await publicAPI.getProducts(
        "",
        `currentPage=${currentPage}&filter=${filter}&filterValue=${filterValue}&pageSize=${pageSize}&${query}`
      );
      setProducts(res.data.products);
      setTotalCount(res.data.totalCount);
    } catch (err) {
      console.log(err);
    }
  };
  const asyncSearch = async () => {
    clearTimeout(debounceTimer);
    setDebounceTimer(
      setTimeout(async () => {
        let query = `title=${inputValue}`;
        if (searchOption === "category") {
          let category = await publicAPI.getCategory("", `title=${inputValue}`);
          if (category.data.categories.length > 0)
            query = `categoryId=${category.data.categories[0]._id}`;
        }
        getProducts(query);
      }, 500)
    );
  };
  useEffect(() => {
    asyncSearch();
  }, [currentPage, filter, filterValue, searchOption, inputValue]);
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          {" "}
          <SearchInputAdmin
            inputValue={inputValue}
            setInputValue={setInputValue}
            setCurrentPage={setCurrentPage}
          ></SearchInputAdmin>
          <SearchOptionsAdmin
            setSearchOption={setSearchOption}
            setCurrentPage={setCurrentPage}
            selected={searchOption}
          ></SearchOptionsAdmin>
          <FilterAdmin
            setFilter={setFilter}
            setFilterValue={setFilterValue}
            selected={`${filter}_${filterValue}`}
            setCurrentPage={setCurrentPage}
          ></FilterAdmin>
        </div>
        <Pagination
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        ></Pagination>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <ConfirmDeleteModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onConfirm={onConfirm}
        ></ConfirmDeleteModal>

        {products.map((product) => (
          <div
            className={s.container}
            style={{ display: "grid", position: "relative" }}
          >
            <ProductPreviewAdmin {...product} />
            <div
              style={{
                opacity: "0",
                display: "flex",
                position: "absolute",
                alignSelf: "center",
                justifySelf: "center",
                zIndex: "2",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box",
              }}
            >
              <button
                onClick={(e) =>
                  navigate(
                    product?._id !== undefined
                      ? "/administrator/products/edit/" + product?._id
                      : "/administrator/products/edit/",
                    e
                  )
                }
                style={{
                  background: "none",
                  height: "fit-content",
                  fontSize: "16px",
                  fontWeight: "600",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                  color: "white",
                  border: "2px solid white",
                  borderRadius: "10px",
                  padding: "8px",
                }}
              >
                Edit
              </button>
              <button
                onClick={() => {
                  setIsOpen(true);
                  setOnConfirm(() => async () => {
                    try {
                      await adminAPI.deleteProduct(product?._id);
                      asyncSearch();
                      setIsOpen(false);
                    } catch (err) {
                      console.log(err);
                    }
                  });
                }}
                style={{
                  background: "none",
                  height: "fit-content",
                  fontSize: "16px",
                  fontWeight: "600",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                  color: "white",
                  border: "2px solid white",
                  borderRadius: "10px",
                  padding: "8px",
                  marginLeft: "10px",
                }}
              >
                Del
              </button>
            </div>
          </div>
        ))}
        <div
          style={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            onClick={async () => {
              try {
                await adminAPI.addProduct({
                  imageURL: "",
                  pictures: ["", "", ""],
                  title: "Новий продукт",
                  price: 0,
                  isAvailable: false,
                  code: null,
                  flag: "",
                  description: "",
                  specs: "",
                  orderInfo: "",
                  category: null,
                });
                if (inputValue === "Новий продукт") asyncSearch();
                else setInputValue("Новий продукт");
              } catch (err) {
                console.log(err);
              }
            }}
            style={{
              border: "4px solid #f5643e",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path d="M12 2c.6 0 1 .4 1 1v8h8c.6 0 1 .4 1 1s-.4 1-1 1h-8v8c0 .6-.4 1-1 1s-1-.4-1-1v-8H3c-.6 0-1-.4-1-1s.4-1 1-1h8V3c0-.6.4-1 1-1z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

const ProductPreviewAdmin = ({
  title = "",
  imageURL = "",
  price = "",
  discountPrice = "",
  discountPeriod = { start: null, end: null },
  isAvailable = "",
  toOrder = { on: false, dayCount: null },
  code = "",
  _id = "",
  flag = "",
}) => {
  const isPeriodValid = () => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };
  const navigate = useConditionalNavigate();
  return (
    <div
      onClick={(e) => navigate("/administrator/product/" + _id, e)}
      className={s.child}
      style={{
        width: "240px",
        height: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        background: "white",
      }}
    >
      {flag && <Flag text={flag}></Flag>}
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={`${HOST}/${imageURL}`}
          style={{ maxHeight: "220px", maxWidth: "100%" }}
        ></img>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          textWrap: "wrap",
        }}
      >
        {code && (
          <span style={{ fontSize: "12px", color: "#ACACAC" }}>
            Код: {code}
          </span>
        )}
        <span className="text_hover">{title}</span>
        {isAvailable ? (
          <span style={{ fontSize: "14px", color: "#6AB416" }}>
            В наявності
          </span>
        ) : toOrder?.on && toOrder?.dayCount ? (
          <span style={{ fontSize: "14px", color: "#ACACAC" }}>
            Під замовлення ({toOrder?.dayCount} днів)
          </span>
        ) : (
          <span style={{ fontSize: "14px", color: "#E75539" }}>
            Немає в наявності
          </span>
        )}
        {(!discountPrice || !isPeriodValid()) && (
          <span
            style={{
              padding: "7px",
              backgroundColor: "#FFF3B5",
              borderRadius: "5px",
              width: "fit-content",
            }}
          >
            {price + " ₴"}
          </span>
        )}
        {discountPrice && isPeriodValid() && (
          <>
            <span style={{ color: "red" }}>
              {discountPeriod.start + " - " + discountPeriod.end}
            </span>
            <div
              style={{
                width: "fit-content",
                backgroundColor: "#FFF3B5",
                borderRadius: "5px",
              }}
            >
              <span
                style={{
                  padding: "7px",
                  width: "fit-content",
                  position: "relative",
                  display: "inline-block",
                  color: "black",
                }}
              >
                {price}
                <span
                  style={{
                    content: '""',
                    position: "absolute",
                    top: "50%",
                    left: "-10%",
                    width: "120%",
                    height: "2px",
                    background: "red",
                    transform: "rotate(45deg)",
                    transformOrigin: "center",
                  }}
                ></span>
              </span>
              <span
                style={{
                  padding: "7px",
                }}
              >
                {discountPrice + " ₴"}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductsAdmin;
