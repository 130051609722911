import React from "react";
import styles from "../styles/Aboutus.module.css";

const Aboutus = ({ aboutUs = { description: "", companyInfo: "" } }) => {
  if (!aboutUs.description && !aboutUs.companyInfo) return null;

  return (
    <>
      <div className={styles.container}>
        {aboutUs.description &&
          aboutUs.description?.split("\n\n").map((block, index) => (
            <div key={index}>
              <span>{block}</span>
            </div>
          ))}
      </div>

      {aboutUs.companyInfo &&
        aboutUs.companyInfo?.split("\n\n\n").map((block, index) => {
          const parts = block?.split("\n\n");
          return (
            <div key={index} className={styles.companyInfoBlock}>
              <h2 className={styles.companyInfoTitle}>{parts[0]}</h2>
              {parts[1]?.split("\n").map((line, subIndex) => {
                const lineParts = line?.split("\t");
                return (
                  <div key={subIndex} className={styles.infoItem}>
                    <span className={styles.infoLabel}>{lineParts[0]}</span>
                    <span>{lineParts[1]}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
    </>
  );
};

export default Aboutus;