import React from "react";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";
import s from "../styles/SearchResults.module.css";

const SearchResults = ({ filter = [], setFilter = () => {}, setInputValue = () => {} }) => {
  const navigate = useConditionalNavigate();
  return (
    <div className={s.resultsContainer}>
      {filter.map((product, index) => {
        return (
          index < 10 && (
            <span
              key={product._id}
              onClick={(e) => {
                navigate("/product/" + product._id, e);
                setFilter([]);
                setInputValue("");
              }}
              className={s.resultItem}
            >
              {product.title}
            </span>
          )
        );
      })}
    </div>
  );
};

export default SearchResults;
