import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import s from "../../styles/general/overlay.module.css";
import DescriptionAdmin from "./description_admin";
import { adminAPI, publicAPI } from "../../api/api";
import HOST from "../../env";
import ConfirmDeleteModal from "./confirm_delete_modal";
import useConditionalNavigate from "../custom_hooks/use_conditional_navigate";

const CategoriesAdmin = () => {
  const [category, setCategory] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(() => {});
  const location = useLocation().pathname.split("/");
  let _id = location[location.length - 1];
  if (_id === "categories") _id = "";
  const getCategory = async () => {
    try {
      const res = await publicAPI.getCategory(_id);
      setCategory(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCategory();
  }, [_id]);
  const [editOn, setEditOn] = useState(false);
  const [text, setText] = useState(category?.title || "");
  const textAreaRef = useRef(null);
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      textarea.value =
        textarea.value.substring(0, start) +
        "\t" +
        textarea.value.substring(end);
      textarea.selectionEnd = end + 1;
      setText(textarea.value);
    }
  };
  useEffect(() => {
    if (textAreaRef.current) {
      const savedScrollPosition = document.documentElement.scrollTop;
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
      window.scrollTo(0, savedScrollPosition);
    }
  }, [text, editOn]);
  useEffect(() => {
    setText(category?.description || "");
    setEditOn(false);
  }, [category]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        margin: "20px",
        gap: "10px",
      }}
    >
      {category?.title !== "Група товарів" ? (
        !editOn ? (
          <DescriptionAdmin
            data={category?.description}
            captionText={category?.title}
            setEditOn={setEditOn}
            deleteOn={true}
            deleteDescription={() =>
              adminAPI.editCategory(_id, { description: "" })
            }
            getCategory={getCategory}
          ></DescriptionAdmin>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <textarea
                value={text}
                ref={textAreaRef}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={handleKeyDown}
                style={{
                  width: "100%",
                }}
              ></textarea>
            </div>
            <div style={{ display: "flex", marginTop: "10px", gap: "10px" }}>
              <button
                onClick={async () => {
                  try {
                    await adminAPI.editCategory(_id, { description: text });
                    getCategory();
                    setEditOn(false);
                  } catch (err) {
                    console.log(err);
                  }
                }}
                style={{
                  backgroundColor: "rgb(106, 180, 22)",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                Зберегти
              </button>
              <button
                onClick={() => {
                  setText(category?.description || "");
                  setEditOn(false);
                }}
                style={{
                  backgroundColor: "rgb(106, 180, 22)",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                Скасувати
              </button>
            </div>
          </div>
        )
      ) : (
        <></>
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "50px",
          gap: 10,
          alignItems: "center",
        }}
      >
        <ConfirmDeleteModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onConfirm={onConfirm}
        ></ConfirmDeleteModal>
        {category?.categories?.map((category) => (
          <Category
            {...category}
            getCategory={getCategory}
            setIsOpen={setIsOpen}
            setOnConfirm={setOnConfirm}
          ></Category>
        ))}
        <div
          style={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            onClick={async () => {
              try {
                await adminAPI.addCategory(_id, {
                  title: "Нова категорія",
                  imageURL: "",
                  itemsIn: 0,
                  description: "",
                  categories: [],
                });
                getCategory();
              } catch (err) {
                console.log(err);
              }
            }}
            style={{
              border: "4px solid #f5643e",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path d="M12 2c.6 0 1 .4 1 1v8h8c.6 0 1 .4 1 1s-.4 1-1 1h-8v8c0 .6-.4 1-1 1s-1-.4-1-1v-8H3c-.6 0-1-.4-1-1s.4-1 1-1h8V3c0-.6.4-1 1-1z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

const Category = ({
  title = "",
  imageURL = "",
  _id = "",
  getCategory = () => {},
  setIsOpen = () => {},
  setOnConfirm = () => {},
}) => {
  const ref = useRef(null);
  const navigate = useConditionalNavigate();
  const [editOn, setEditOn] = useState(false);
  const [Category, setCategory] = useState({ title, imageURL, file: null });
  useEffect(() => {
    setCategory({ title, imageURL });
  }, [title, imageURL]);
  return (
    <div
      className={!editOn && s.container}
      style={{ position: "relative", display: "grid" }}
    >
      <div
        className={s.child}
        onClick={(e) =>
          !editOn && navigate("/administrator/subcategories/" + _id, e)
        }
        style={{
          position: "relative",
          overflow: "hidden",
          width: "230px",
          height: "280px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "10px",
          background: "white",
        }}
      >
        <div
          style={{
            flexGrow: "1",
            position: "relative",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              filter: editOn ? "brightness(70%)" : "",
            }}
          >
            <img
              style={{
                maxWidth: "100%",
              }}
              src={`${HOST}/${Category.imageURL}`}
              ref={ref}
            ></img>
          </div>
          {editOn && (
            <div
              onDrop={async (e) => {
                {
                  e.preventDefault();
                  const files = e.dataTransfer.files;
                  if (
                    /^image\/(jpeg|png|gif|webp|bmp|svg\+xml)$/.test(
                      files[0]?.type
                    )
                  ) {
                    function handleFile(file) {
                      var reader = new FileReader();
                      reader.onload = function (event) {
                        var url = event.target.result;
                        ref.current.src = url;
                      };
                      reader.readAsDataURL(file);
                    }
                    handleFile(files[0]);
                    const newFileName = `${Number(new Date())}_${
                      files[0].name
                    }`;
                    const updatedFile = new File([files[0]], newFileName, {
                      type: files[0].type,
                    });
                    setCategory({ ...Category, file: updatedFile });
                  }
                }
              }}
              onDragOver={(e) => e.preventDefault()}
              style={{
                position: "absolute",
                top: "0",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                border: "2px dashed white",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color: "white",
                  position: "absolute",
                  top: "10px",
                  fontSize: "18px",
                }}
              >
                Drag & Drop
              </span>
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {editOn ? (
            <input
              onChange={(e) =>
                setCategory({ ...Category, title: e.target.value })
              }
              value={Category.title}
            ></input>
          ) : (
            <span className="text_hover">{Category.title}</span>
          )}
        </div>
      </div>

      {!editOn ? (
        <div
          style={{
            opacity: "0",
            display: "flex",
            position: "absolute",
            alignSelf: "center",
            justifySelf: "center",
            zIndex: "2",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        >
          <button
            onClick={() => setEditOn(true)}
            style={{
              background: "none",
              height: "fit-content",
              fontSize: "16px",
              fontWeight: "600",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
              color: "white",
              border: "2px solid white",
              borderRadius: "10px",
              padding: "8px",
            }}
          >
            Edit
          </button>
          <button
            onClick={() => {
              setIsOpen(true)
              setOnConfirm(() => async () => {
                try {
                  await adminAPI.deleteCategory(_id);
                  getCategory();
                } catch (err) {
                  console.log(err);
                }
              });
            }}
            style={{
              background: "none",
              height: "fit-content",
              fontSize: "16px",
              fontWeight: "600",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
              color: "white",
              border: "2px solid white",
              borderRadius: "10px",
              padding: "8px",
              marginLeft: "10px",
            }}
          >
            Del
          </button>
        </div>
      ) : (
        <div style={{ display: "flex", marginTop: "10px", gap: "10px" }}>
          <button
            onClick={async () => {
              const formData = new FormData();
              formData.append("title", Category.title);
              formData.append("file", Category.file);
              try {
                await adminAPI.editCategory(_id, formData);
                getCategory();
                setEditOn(false);
              } catch (err) {
                console.log(err);
              }
            }}
            style={{
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            Зберегти
          </button>
          <button
            onClick={() => {
              ref.current.src = `${HOST}/${imageURL}`;
              setCategory({ title, imageURL });
              setEditOn(false);
            }}
            style={{
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            Скасувати
          </button>
        </div>
      )}
    </div>
  );
};

export const recursiveSearch = (categories = [], id) => {
  if (categories.length === 0) return undefined;

  let result = categories.find((category) => category._id === id);

  if (result) {
    return result;
  } else {
    for (const category of categories) {
      const found = recursiveSearch(category.categories, id);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export default CategoriesAdmin;
