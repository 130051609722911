import { useEffect, useState } from "react";
import { publicAPI } from "../../api/api";
import Contacts2Admin from "./contacts2_admin";
import ScheduleAdmin from "./schedule_admin";
import AboutusAdmin from "./aboutus_admin";
import DeliveryAdmin from "./delivery_admin";
import ReturnPolicyAdmin from "./return_policy_admin";
import PopupAdmin from "./popup_admin";
import Overlay2 from "./overlay2";

const Info = () => {
  const [info, setInfo] = useState({})
  const getInfo = async () => {
    const info = await publicAPI.getInfo()
    setInfo(info?.data || {})
  }
  useEffect(() => {
    getInfo()
  }, [])
  return <div style={{
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "30px"
  }}>
    <Overlay2><PopupAdmin popup={info?.popup} getInfo={getInfo}></PopupAdmin></Overlay2>
    <Overlay2><Contacts2Admin contacts={info?.contacts} getInfo={getInfo}></Contacts2Admin></Overlay2>
    <Overlay2><ScheduleAdmin schedule={info?.schedule} getInfo={getInfo}></ScheduleAdmin></Overlay2>
    <Overlay2><AboutusAdmin aboutUs={info?.aboutUs} getInfo={getInfo}></AboutusAdmin></Overlay2>
    <Overlay2><DeliveryAdmin delivery={info?.delivery} getInfo={getInfo}></DeliveryAdmin></Overlay2>
    <Overlay2><ReturnPolicyAdmin returnPolicy={info?.returnPolicy} getInfo={getInfo}></ReturnPolicyAdmin></Overlay2>
  </div>
};

export default Info;
