import s from "../styles/general/icons.module.css";
import s2 from "../styles/general/animations.module.css";
import styles from "../styles/Menu.module.css"; // Підключення стилів з нового файлу
import { useEffect, useState } from "react";
import { publicAPI } from "../api/api";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";

const Menu = () => {
  const navigate = useConditionalNavigate();
  const [categories, setCategories] = useState([]);
  const [isHidden, setIsHidden] = useState(true);
  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await publicAPI.getCategory();
        setCategories(res.data.categories);
      } catch (err) {
        console.log(err);
      }
    };
    getCategory();
  }, []);

  return (
    <div className={styles.menuContainer}>
      <ul className={s2.parent + " " + styles.menuList}>
        <li className={styles.menuItem}>
          <a className={s2.menu} onClick={(e) => navigate("/", e)}>
            Головна
          </a>
        </li>
        <li
          id={isHidden ? s2.hidden : ""}
          className={s2.menu + " " + s.content7 + " " + styles.menuItem}
          onMouseEnter={() => setIsHidden(false)}
          onMouseLeave={() => setIsHidden(true)}
        >
          <a onClick={(e) => navigate("/", e)}>Товари та послуги</a>
          <ul className={styles.submenuList}>
            {categories.map((category) => (
              <li
                className={s2.submenu + " " + styles.submenuItem}
                onClick={(e) => {
                  navigate("/categories/" + category._id, e);
                  setIsHidden(true);
                }}
                key={category._id}
              >
                <a>{category.title}</a>
              </li>
            ))}
          </ul>
        </li>
        <li className={styles.menuItem}>
          <a className={s2.menu} onClick={(e) => navigate("/aboutus", e)}>
            Про нас
          </a>
        </li>
        <li className={styles.menuItem}>
          <a className={s2.menu} onClick={(e) => navigate("/contacts", e)}>
            Контакти
          </a>
        </li>
        <li className={styles.menuItem}>
          <a className={s2.menu} onClick={(e) => navigate("/delivery", e)}>
            Доставка та оплата
          </a>
        </li>
        <li className={styles.menuItem}>
          <a className={s2.menu} onClick={(e) => navigate("/return", e)}>
            Повернення та обмін
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
