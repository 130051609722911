import React from "react";
import Caption from "./caption";
import styles from "../styles/Description.module.css";

const Description = ({
  data = "",
  captionText = "",
  productDescription = false,
  marginCaption = "0",
  marginData = "0",
}) => {
  return (
    <div>
      {captionText && !productDescription && (
        <Caption text={captionText} margin={marginCaption}></Caption>
      )}
      {data.split("\n\n").map((text, index) => (
        <div className={`${styles.textBlock}`} key={index}>
          {text.split("\n").map((substring, subIndex) => (
            <span
              style={{ margin: marginData }}
              key={subIndex}
              className={
                productDescription && index === 0 ? styles.bold : styles.normal
              }
            >
              {substring}
            </span>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Description;
