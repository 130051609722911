import { useEffect, useState } from "react";
import Aboutus from "./components/aboutus";
import Footer from "./components/footer";
import Header from "./components/header";
import Menu from "./components/menu";
import Slider from "./components/slider";
import Underheader from "./components/underheader";
import Caption from "./components/caption";
import { Route, Routes, useLocation } from "react-router-dom";
import Contacts from "./components/contacts";
import Schedule from "./components/schedule";
import Contacts2 from "./components/contacts2";
import Delivery from "./components/delivery";
import ScheduleModal from "./components/schedule_modal";
import Description from "./components/description";
import ProductDetails from "./components/product_details";
import CartModal from "./components/cart_modal";
import Popup from "./components/popup";
import Filter from "./components/filter";
import Products from "./components/products";
import Categories from "./components/categories";
import Reccomendations from "./components/recomendations";
import { publicAPI } from "./api/api";
import CreateFeedBack from "./components/create_feedback";
import FeedbacksPage from "./components/feedback";

// Імпорт модуля стилів
import styles from "./styles/App.module.css";

const App = () => {
  const [modalOn, setModalOn] = useState(false);
  const [sidebarOn, setSidebarOn] = useState(true);
  const [feedbackOnSidebar, setFeedbackOnSidebar] = useState(true);
  const [sliderOn, setSliderOn] = useState(true);
  const [cart, setCart] = useState([]);
  const [cartOn, setCartOn] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [info, setInfo] = useState({});
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [filterValue, setFilterValue] = useState(false);
  const [reccomendationsOn, setReccomendationsOn] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/" && sliderOn) setSliderOn(false);
    else if (location.pathname === "/" && !sliderOn) setSliderOn(true);
  }, [location]);

  useEffect(() => {
    setCart(JSON.parse(localStorage.getItem("cart") || "[]"));
    const getInfo = async () => {
      try {
        const res = await publicAPI.getInfo();
        setInfo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getInfo();
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {cartOn && (
          <CartModal
            cart={cart}
            setCart={setCart}
            setCartOn={setCartOn}
          ></CartModal>
        )}
        {modalOn && (
          <ScheduleModal
            schedule={info?.schedule}
            setModalOn={setModalOn}
          ></ScheduleModal>
        )}
        <Header
          minPrice={minPrice}
          maxPrice={maxPrice}
          filterValue={filterValue}
          setMinPrice={setMinPrice}
          setMaxPrice={setMaxPrice}
          setFilterValue={setFilterValue}
          cart={cart}
          setCartOn={setCartOn}
        ></Header>
        <Popup schedule={info?.schedule} popup={info?.popup}></Popup>
        <div className={styles.mainContent}>
          <div className={styles.fullWidth}>
            <Underheader
              cart={cart}
              contacts={info?.contacts}
              setModalOn={setModalOn}
              setCartOn={setCartOn}
            ></Underheader>
            <Menu></Menu>
            <div className={styles.flexContainer}>
              {sidebarOn && (
                <aside className={styles.sidebar}>
                  {filterOn && (
                    <Filter
                      minPrice={minPrice}
                      maxPrice={maxPrice}
                      filterValue={filterValue}
                      setMinPrice={setMinPrice}
                      setMaxPrice={setMaxPrice}
                      setFilterValue={setFilterValue}
                    ></Filter>
                  )}
                  <Contacts contacts={info?.contacts}></Contacts>
                  <Schedule schedule={info?.schedule}></Schedule>
                  {feedbackOnSidebar && (
                    <FeedbacksPage sidebar={true}></FeedbacksPage>
                  )}
                </aside>
              )}
              <div
                className={
                  sidebarOn ? styles.mainWithSidebar : styles.mainWithoutSidebar
                }
              >
                {sliderOn && <Slider></Slider>}
                <Routes>
                  <Route
                    path="/"
                    element={
                      <div className={styles.paddedContent}>
                        <div className={styles.categoryContainer}>
                          <Categories
                            setCategoryId={setCategoryId}
                          ></Categories>
                        </div>
                        <Aboutus
                          data={{
                            description: info?.aboutUs?.description || "",
                            companyInfo: "",
                          }}
                        ></Aboutus>
                        <Caption text={"Вітрина"} margin="20px 0"></Caption>
                        <Products
                          categoryId={categoryId}
                          minPrice={minPrice}
                          maxPrice={maxPrice}
                          filterValue={filterValue}
                          cart={cart}
                          setCart={setCart}
                          phone={info?.contacts?.phone}
                        ></Products>
                      </div>
                    }
                  ></Route>

                  <Route
                    path="/categories/:any"
                    element={
                      <div className={styles.paddedContent}>
                        <div className={styles.categoryContainer}>
                          <Categories
                            setCategoryId={setCategoryId}
                          ></Categories>
                        </div>
                        <Products
                          categoryId={categoryId}
                          setFilterOn={setFilterOn}
                          filterValue={filterValue}
                          minPrice={minPrice}
                          maxPrice={maxPrice}
                          cart={cart}
                          setCart={setCart}
                          phone={info?.contacts?.phone}
                        ></Products>
                      </div>
                    }
                  ></Route>

                  <Route
                    path="/feedback"
                    element={
                      <div className={styles.feedbackContainer}>
                        <Caption
                          text="Відгуки про компанію Coffeeride"
                          margin="0 0 30px 0"
                        ></Caption>
                        <CreateFeedBack></CreateFeedBack>
                        <FeedbacksPage
                          setReccomendationsOn={setReccomendationsOn}
                          setFeedbackOnSidebar={setFeedbackOnSidebar}
                        ></FeedbacksPage>
                      </div>
                    }
                  ></Route>

                  <Route
                    path="/aboutus"
                    element={
                      <div className={styles.paddedContent}>
                        <Caption text="Про нас" margin="0 0 20px 0"></Caption>
                        <Aboutus aboutUs={info?.aboutUs}></Aboutus>
                      </div>
                    }
                  ></Route>

                  <Route
                    path="/contacts"
                    element={
                      <div className={styles.paddedContent}>
                        <Contacts2 contacts={info?.contacts}></Contacts2>
                      </div>
                    }
                  ></Route>

                  <Route
                    path="/delivery"
                    element={
                      <div className={styles.paddedContent}>
                        <Delivery delivery={info?.delivery}></Delivery>
                      </div>
                    }
                  ></Route>

                  <Route
                    path="/return"
                    element={
                      <div className={styles.paddedContent}>
                        <Description
                          data={info?.returnPolicy}
                          captionText="Повернення та обмін"
                          marginCaption="0 0 10px 0"
                          marginData="10px 0"
                        ></Description>
                      </div>
                    }
                  ></Route>

                  <Route
                    path="/product/:any"
                    element={
                      <ProductDetails
                        setSidebarOn={setSidebarOn}
                        setCart={setCart}
                        cart={cart}
                        phone={info?.contacts?.phone}
                      ></ProductDetails>
                    }
                  ></Route>

                  <Route
                    path="/search_results/:any"
                    element={
                      <Products
                        categoryId={categoryId}
                        minPrice={minPrice}
                        maxPrice={maxPrice}
                        filterValue={filterValue}
                        cart={cart}
                        setCart={setCart}
                        phone={info?.contacts?.phone}
                      ></Products>
                    }
                  ></Route>
                </Routes>
                {sidebarOn && reccomendationsOn && (
                  <>
                    <Reccomendations captionText="Ви переглядали"></Reccomendations>
                    <Reccomendations captionText="Ми рекомендуємо"></Reccomendations>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

export const getCookieValue = (name) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export default App;
