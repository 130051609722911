import React from "react";
import styles from "../styles/Contacts.module.css";

const Contacts = ({
  contacts = {
    company: "",
    contactPerson: "",
    phone: "",
    site: "",
    email: "",
    telegram: "",
    viber: "",
    address: "",
  },
}) => {
  return (
    <div className={styles.contactsContainer}>
      <div className={styles.contactsHeader}>
        <h6 className={styles.contactsTitle}>
          <span className={styles.highlight}>К</span>онтакти
        </h6>
      </div>

      <div className={styles.contactsContent}>
        <div>
          <h6 className={styles.sectionTitle}>Компанія</h6>
          <p>{contacts?.company}</p>
        </div>
        <div className={styles.section}>
          <h6 className={styles.sectionTitle}>Контактна особа</h6>
          <p>{contacts?.contactPerson}</p>
        </div>
        <div className={styles.section}>
          <h6 className={styles.sectionTitle}>Телефон</h6>
          <p>
            {contacts?.phone?.slice(0, 4) +
              " (" +
              contacts?.phone?.slice(4, 6) +
              ") " +
              contacts?.phone?.slice(6, 9) +
              "-" +
              contacts?.phone?.slice(9, 11) +
              "-" +
              contacts?.phone?.slice(11, 13)}
          </p>
        </div>
        <div className={styles.section}>
          <h6 className={styles.sectionTitle}>Сайт</h6>
          <p className={styles.link}>{contacts?.site}</p>
        </div>
        <div className={styles.section}>
          <h6 className={styles.sectionTitle}>Email</h6>
          <p className={styles.link}>{contacts?.email}</p>
        </div>
        <div className={styles.section}>
          <h6 className={styles.sectionTitle}>Telegram</h6>
          <p>{contacts?.telegram}</p>
        </div>
        <div className={styles.section}>
          <h6 className={styles.sectionTitle}>Viber</h6>
          <p>{contacts?.viber}</p>
        </div>
        <div className={styles.section}>
          <h6 className={styles.sectionTitle}>Адреса</h6>
          <p className={styles.address}>{contacts?.address}</p>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
