import React, { useEffect, useRef, useState } from "react";
import { adminAPI } from "../../api/api";
import Caption from "../caption";

const AboutusAdmin = ({
  aboutUs = { description: "", companyInfo: "" },
  editOn = false,
  setEditOn = () => {},
  getInfo = () => {},
}) => {
  const [text, setText] = useState(aboutUs?.description);
  const [text2, setText2] = useState(aboutUs?.companyInfo);
  useEffect(() => {
    setText(aboutUs?.description);
    setText2(aboutUs?.companyInfo);
  }, [aboutUs]);
  const textAreaRef = useRef(null);
  const textAreaRef2 = useRef(null);
  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      const textarea = event.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      textarea.value =
        textarea.value.substring(0, start) +
        "\t" +
        textarea.value.substring(end);
      textarea.selectionEnd = end + 1;
      setText2(textarea.value);
    }
  };
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [text, editOn]);
  useEffect(() => {
    if (textAreaRef2.current) {
      textAreaRef2.current.style.height = "auto";
      textAreaRef2.current.style.height =
        textAreaRef2.current.scrollHeight + "px";
    }
  }, [text2, editOn]);
  if (editOn)
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <textarea
          value={text}
          ref={textAreaRef}
          onChange={(e) => setText(e.target.value)}
          style={{
            width: "100%",
          }}
        />
        <textarea
          value={text2}
          ref={textAreaRef2}
          onChange={(e) => setText2(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{
            width: "100%",
          }}
        />
        <div style={{ display: "flex", gap: "20px" }}>
          <button
            onClick={async () => {
              await adminAPI.editInfo({
                aboutUs: {
                  description: text,
                  companyInfo: text2,
                },
              });
              getInfo();
              setEditOn(false);
            }}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Зберегти
          </button>
          <button
            onClick={() => setEditOn(false)}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Скасувати
          </button>
        </div>
      </div>
    );
  return (
    <div
      style={{
        border: "0.5px solid rgb(193, 193, 193)",
        padding: "10px",
      }}
    >
      <Caption text="Про нас" margin="0px 0px 20px"></Caption>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        {aboutUs.description &&
          aboutUs.description.split("\n\n").map((block) => {
            // block = block.split("\b").filter((text) => text !== "");
            return (
              <div>
                {/* <span style={{ fontWeight: 600 }}>
                  {block.length > 1 ? block[0] : ""}
                </span>
                <span>{block.length > 1 ? block[1] : block[0]}</span> */}
                <span>{block}</span>
              </div>
            );
          })}
      </div>
      {aboutUs.companyInfo &&
        aboutUs.companyInfo?.split("\n\n\n").map((block) => {
          block = block?.split("\n\n");
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "30px 0",
              }}
            >
              <h2
                style={{
                  fontWeight: 600,
                  marginBottom: "25px",
                  fontSize: "18px",
                }}
              >
                {block[0]}
              </h2>
              {block[1]?.split("\n").map((block) => {
                block = block?.split("\t");
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        fontWeight: 600,
                        width: "300px",
                        padding: "5px",
                      }}
                    >
                      {block[0]}
                    </span>
                    <span>{block[1]}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default AboutusAdmin;
