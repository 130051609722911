import React, { useState } from "react";
import styles from "../styles/Pagination.module.css";

const PORTION_SIZE = 3;

let Pagination = ({
  totalCount = 0,
  pageSize = 10,
  currentPage = 1,
  setCurrentPage = () => {},
}) => {
  let pagesCount = Math.ceil(totalCount / pageSize);
  let portionsCount = Math.ceil(pagesCount / PORTION_SIZE);
  let [currentPortionNumber, setCurrentPortion] = useState(1);
  let portionsLeftBorder = (currentPortionNumber - 1) * PORTION_SIZE + 1;
  let portionsRightBorder = currentPortionNumber * PORTION_SIZE;

  let pages = [];
  for (let i = 1; i <= pagesCount; i++) {
    pages.push(i);
  }

  if (totalCount === 0) return null;

  return (
    <div className={styles.paginationContainer}>
      {currentPortionNumber > 1 && (
        <>
          <button
            className={styles.paginationButton}
            onClick={() => {
              setCurrentPortion(currentPortionNumber - 1);
            }}
          >
            ←
          </button>
          <span
            className={`${styles.pageNumber} ${
              currentPage === 1 ? styles.activePage : ""
            }`}
            onClick={() => setCurrentPage(1)}
          >
            1
          </span>
          <span className={styles.ellipsis}>...</span>
        </>
      )}
      {pages
        .filter((p) => p >= portionsLeftBorder && p <= portionsRightBorder)
        .map((p) => (
          <span
            key={p}
            className={`${styles.pageNumber} ${
              currentPage === p ? styles.activePage : ""
            }`}
            onClick={() => setCurrentPage(p)}
          >
            {p}
          </span>
        ))}

      {currentPortionNumber !== portionsCount && (
        <>
          <span className={styles.ellipsis}>...</span>
          <span
            className={`${styles.pageNumber} ${
              currentPage === pages[pages.length - 1]
                ? styles.activePage
                : ""
            }`}
            onClick={() => setCurrentPage(pages[pages.length - 1])}
          >
            {pages[pages.length - 1]}
          </span>
          <button
            className={styles.paginationButton}
            onClick={() => {
              setCurrentPortion(currentPortionNumber + 1);
            }}
          >
            →
          </button>
        </>
      )}
    </div>
  );
};

export default Pagination;
