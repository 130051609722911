import { useEffect, useState } from "react";
import { adminAPI } from "../../api/api";

const ScheduleAdmin = ({
  schedule = {},
  editOn = false,
  setEditOn = () => {},
  getInfo = () => {},
}) => {
  const [Schedule, setSchedule] = useState(schedule);
  useEffect(() => {
    setSchedule(schedule);
  }, [schedule]);
  if (editOn)
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Понеділок</label>
            <input
              onChange={(e) =>
                setSchedule({ ...Schedule, Monday: e.target.value })
              }
              value={Schedule.Monday}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Вівторок</label>
            <input
              onChange={(e) =>
                setSchedule({ ...Schedule, Tuesday: e.target.value })
              }
              value={Schedule.Tuesday}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Середа</label>
            <input
              onChange={(e) =>
                setSchedule({ ...Schedule, Wednesday: e.target.value })
              }
              value={Schedule.Wednesday}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Четвер</label>
            <input
              onChange={(e) =>
                setSchedule({ ...Schedule, Thursday: e.target.value })
              }
              value={Schedule.Thursday}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>П'ятниця</label>
            <input
              onChange={(e) =>
                setSchedule({ ...Schedule, Friday: e.target.value })
              }
              value={Schedule.Friday}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Субота</label>
            <input
              onChange={(e) =>
                setSchedule({ ...Schedule, Saturday: e.target.value })
              }
              value={Schedule.Saturday}
            ></input>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <label>Неділя</label>
            <input
              onChange={(e) =>
                setSchedule({ ...Schedule, Sunday: e.target.value })
              }
              value={Schedule.Sunday}
            ></input>
          </div>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <button
            onClick={async () => {
              await adminAPI.editInfo({
                schedule: Schedule,
              });
              getInfo();
              setEditOn(false);
            }}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Зберегти
          </button>
          <button
            onClick={() => setEditOn(false)}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Скасувати
          </button>
        </div>
      </div>
    );
  return (
    <div style={{ border: "0.5px solid #C1C1C1" }}>
      <div style={{ padding: "5px 20px", backgroundColor: "#333" }}>
        <h6 style={{ color: "#fff" }}>
          <span style={{ color: "#69B316" }}>Г</span>рафік роботи
        </h6>
      </div>
      <ul
        style={{
          listStyleType: "none",
          padding: "10px 20px",
        }}
      >
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Понеділок</span>
          <span>{Schedule?.Monday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Вівторок</span>
          <span>{Schedule?.Tuesday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Середа</span>
          <span>{Schedule?.Wednesday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Четвер</span>
          <span>{Schedule?.Thursday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>П'ятниця</span>
          <span>{Schedule?.Friday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Субота</span>
          <span>{Schedule?.Saturday}</span>
        </li>
        <li style={{ display: "flex", padding: "5px 0" }}>
          <span style={{ width: "105px" }}>Неділя</span>
          <span>{Schedule?.Sunday}</span>
        </li>
      </ul>
    </div>
  );
};

export default ScheduleAdmin;
