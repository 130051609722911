import React, { useEffect, useRef, useState } from "react";
import s from "../../styles/general/icons.module.css";
import Caption from "../caption";
import { adminAPI } from "../../api/api";

const DeliveryAdmin = ({
  delivery = {
    deliveryWays: "",
    paymentWays: "",
    warranty: "",
  },
  editOn = false,
  setEditOn = () => {},
  getInfo = () => {},
}) => {
  const [text, setText] = useState(delivery?.deliveryWays);
  const [text2, setText2] = useState(delivery?.paymentWays);
  const [text3, setText3] = useState(delivery?.warranty);
  useEffect(() => {
    setText(delivery?.deliveryWays);
    setText2(delivery?.paymentWays);
    setText3(delivery?.warranty);
  }, [delivery]);
  const textAreaRef = useRef(null);
  const textAreaRef2 = useRef(null);
  const textAreaRef3 = useRef(null);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [text, editOn]);
  useEffect(() => {
    if (textAreaRef2.current) {
      textAreaRef2.current.style.height = "auto";
      textAreaRef2.current.style.height =
        textAreaRef2.current.scrollHeight + "px";
    }
  }, [text2, editOn]);
  useEffect(() => {
    if (textAreaRef3.current) {
      textAreaRef3.current.style.height = "auto";
      textAreaRef3.current.style.height =
        textAreaRef3.current.scrollHeight + "px";
    }
  }, [text3, editOn]);
  if (editOn)
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <textarea
          value={text}
          ref={textAreaRef}
          onChange={(e) => setText(e.target.value)}
          style={{
            width: "100%",
          }}
        />
        <textarea
          value={text2}
          ref={textAreaRef2}
          onChange={(e) => setText2(e.target.value)}
          style={{
            width: "100%",
          }}
        />
        <textarea
          value={text3}
          ref={textAreaRef3}
          onChange={(e) => setText3(e.target.value)}
          style={{
            width: "100%",
          }}
        />
        <div style={{ display: "flex", gap: "20px" }}>
          <button
            onClick={async () => {
              await adminAPI.editInfo({
                delivery: {
                  deliveryWays: text,
                  paymentWays: text2,
                  warranty: text3,
                },
              });
              getInfo();
              setEditOn(false);
            }}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Зберегти
          </button>
          <button
            onClick={() => setEditOn(false)}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Скасувати
          </button>
        </div>
      </div>
    );
  return (
    <div
      style={{
        border: "0.5px solid rgb(193, 193, 193)",
        padding: "10px",
      }}
    >
      <Caption text={"Умови доставки та оплати"}></Caption>
      <div style={{ margin: "30px 0px" }}>
        <span
          className={s["content" + 13]}
          style={{ color: "#f5643e", fontSize: "18px" }}
        >
          Способи доставки
        </span>
        {delivery?.deliveryWays?.split("\n\n").map((text) => {
          return (
            <div
              style={{
                margin: "20px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {text.split("\n").map((substring) => (
                <span>{substring}</span>
              ))}
            </div>
          );
        })}
      </div>
      <div style={{ margin: "30px 0px" }}>
        <span
          className={s["content" + 14]}
          style={{ color: "#f5643e", fontSize: "18px" }}
        >
          Способи оплати
        </span>
        {delivery?.paymentWays?.split("\n\n").map((text) => {
          return (
            <div
              style={{
                margin: "20px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {text.split("\n").map((substring) => (
                <span>{substring}</span>
              ))}
            </div>
          );
        })}
      </div>
      <div style={{ margin: "30px 0px" }}>
        <span
          className={s["content" + 13]}
          style={{ color: "#f5643e", fontSize: "18px" }}
        >
          Гарантія
        </span>
        {delivery?.warranty?.split("\n\n").map((text) => {
          return (
            <div
              style={{
                margin: "20px 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {text.split("\n").map((substring) => (
                <span>{substring}</span>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DeliveryAdmin;
