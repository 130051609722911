import { useState } from "react";
import { adminAPI } from "../../api/api";

const Login = ({ setIsLogged }) => {
  const [error, setError] = useState("");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <form
        style={{
          display: "flex",
          gap: "5px",
          flexDirection: "column",
          alignItems: "center",
        }}
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            await adminAPI.login({
              login: e.currentTarget[0].value,
              password: e.currentTarget[1].value,
            });
            setIsLogged(true);
          } catch (err) {
            setError(err?.response?.data?.message || "No server response");
          }
        }}
      >
        <label htmlFor="email" style={{ fontSize: "18px" }}>
          Login
        </label>
        <input
          id="email"
          type="text"
          placeholder="Enter your email"
          autoComplete="off"
          style={{ borderRadius: "10px", outline: "none", padding: "5px", width: "100%" }}
        />
        <label htmlFor="password" style={{ fontSize: "18px" }}>
          Password
        </label>
        <input
          id="password"
          type="password"
          placeholder="Enter your password"
          style={{ borderRadius: "10px", outline: "none", padding: "5px", width: "100%" }}
        />
        <div style={{ display: "flex", marginTop: "10px", gap: "10px" }}>
          <button
            type="submit"
            style={{
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
              border: "none",
              borderRadius: "8px",
              padding: "8px",
            }}
          >
            Sign in
          </button>
          <button
            onClick={() => window.history.back()}
            style={{
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
              border: "none",
              borderRadius: "8px",
              padding: "8px",
            }}
          >
            Back
          </button>
        </div>
      </form>
      {error && <span style={{ color: "red", margin: "20px 0" }}>{error}</span>}
    </div>
  );
};

export default Login;
