import React, { useState } from "react";
import s from "../styles/general/icons.module.css";
import styles from "../styles/Underheader.module.css";
import SearchResults from "./search_results";
import { publicAPI } from "../api/api";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";

const Underheader = ({
  contacts = {
    company: "",
    contactPerson: "",
    phone: "",
    site: "",
    email: "",
    telegram: "",
    viber: "",
    address: "",
  },
  setModalOn,
  cart = [],
  setCartOn = () => {},
}) => {
  const navigate = useConditionalNavigate();
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [filter, setFilter] = useState([]);
  
  const getProducts = async (query = "") => {
    try {
      const res = await publicAPI.getProducts("", `${query}`);
      setFilter(res.data.products);
    } catch (err) {
      console.log(err);
    }
  };
  
  return (
    <div className={styles.underheader}>
      <div className={styles.infoWrapper}>
        <div className={styles.companyInfo}>
          <div className={styles.contactRow}>
            <div className={`${s.content} ${styles.iconBox}`}></div>
            <span>{contacts?.company}</span>
          </div>
          <div className={styles.contactRow}>
            <div className={`${s.content2} ${styles.iconBox}`}></div>
            <span>{contacts?.phone}</span>
          </div>
        </div>
        <div className={styles.companyInfo}>
          <div className={styles.contactRow}>
            <div className={`${s.content3} ${styles.iconBox}`}></div>
            <span
              onClick={() => {
                setModalOn(true);
                document.getElementsByTagName("body")[0].style.overflow =
                  "hidden";
              }}
              className={styles.clickableText}
            >
              Графік роботи
            </span>
          </div>
          <div className={styles.contactRow}>
            <div className={`${s.content4} ${styles.iconBox}`}></div>
            <span
              onClick={(e) => navigate("/feedback", e)}
              className={styles.clickableText}
            >
              Залишити відгук
            </span>
          </div>
        </div>
      </div>

      <div className={styles.searchWrapper}>
        <div
          onClick={(e) => navigate("/", e)}
          className={styles.logoWrapper}
        >
          <img src="/images/4532869852_w350_h100_coffee-ride.webp"></img>
          <span className={styles.address}>{contacts?.address}</span>
        </div>
        
        <div className={styles.searchInputWrapper}>
          <input
            onBlur={() => {
              setTimeout(() => {
                clearTimeout(debounceTimer);
                setFilter([]);
                setInputValue("");
              }, 300);
            }}
            placeholder="пошук по каталогу товарів..."
            className={styles.searchInput}
            onInput={(e) => {
              clearTimeout(debounceTimer);
              setInputValue(e.target.value);
              if (e.target.value === "") return setFilter([]);
              setDebounceTimer(
                setTimeout(() => {
                  getProducts(`title=${e.target.value}`);
                }, 1000)
              );
            }}
            onKeyDown={(e) => {
              if ((e.key === "Enter" || e.keyCode === 13) && inputValue !== "") {
                navigate("search_results/" + inputValue, e);
                clearTimeout(debounceTimer);
                setFilter([]);
                setInputValue("");
              }
            }}
            value={inputValue}
            onFocus={(e) => {
              e.preventDefault();
              e.target.style.outline = "none";
            }}
          />
          {filter.length > 0 && (
            <SearchResults
              filter={filter}
              setFilter={setFilter}
              setInputValue={setInputValue}
            />
          )}
          <div
            onClick={(e) => {
              if (inputValue !== "") navigate("search_results/" + inputValue, e);
            }}
            className={`${s.content5} ${styles.searchButton}`}
          ></div>
        </div>

        <div className={styles.cartWrapper}>
          <div
            onClick={() => setCartOn(true)}
            className={`${s.content6} ${styles.cartIcon}`}
          >
            {cart.length > 0 && (
              <span className={styles.cartCount}>{cart.length}</span>
            )}
          </div>
          <span onClick={() => setCartOn(true)} className={styles.cartText}>
            Кошик{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Underheader;