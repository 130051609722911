import s from "../../styles/general/overlay.module.css"

const OrderInfoAdmin = ({ orderInfo = "", setEditOn = () => {} }) => {
  if (orderInfo === "") {
    return (
      <div
        className={s.container}
        style={{ position: "relative", height: "30px" }}
      >
        <div
          className={s.child}
          style={{ background: "white", height: "100%" }}
        ></div>
        <div
          style={{
            opacity: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            position: "absolute",
            top: "0",
            zIndex: "2",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        >
          <button
            onClick={() => setEditOn(true)}
            style={{
              background: "none",
              height: "fit-content",
              fontSize: "16px",
              fontWeight: "600",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
              color: "white",
              border: "2px solid white",
              borderRadius: "10px",
              padding: "8px",
            }}
          >
            Add
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className={s.container} style={{ position: "relative" }}>
      <div className={s.child} style={{ background: "white" }}>
        {orderInfo.split("\n\n").map((block) => {
          return (
            <div
              style={{
                margin: "10px 0",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {block.split("\n").map((substring) => {
                const info = substring.split("\t");
                return (
                  <div>
                    <span style={{ fontWeight: "600" }}>
                      {info[0] !== undefined ? info[0] : ""}
                    </span>
                    {info[1] !== undefined ? " " + info[1] : " "}
                    <span></span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div
        style={{
          opacity: "0",
          width: "100%",
          height: "100%",
          display: "flex",
          position: "absolute",
          top: "0",
          zIndex: "2",
          alignItems: "center",
          justifyContent: "center",
          boxSizing: "border-box",
        }}
      >
        <button
          onClick={() => setEditOn(true)}
          style={{
            background: "none",
            height: "fit-content",
            fontSize: "16px",
            fontWeight: "600",
            backgroundColor: "transparent",
            outline: "none",
            cursor: "pointer",
            color: "white",
            border: "2px solid white",
            borderRadius: "10px",
            padding: "8px",
          }}
        >
          Edit
        </button>
      </div>
    </div>
  );
};

export default OrderInfoAdmin;
