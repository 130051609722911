import { useEffect, useRef, useState } from "react";
import s from "../../styles/general/icons.module.css";
import { adminAPI } from "../../api/api";

const PopupAdmin = ({
  editOn = false,
  setEditOn = () => {},
  getInfo = () => {},
  popup = {
    text: "",
    vacationOn: false,
    vacationDates: {
      start: null,
      end: null,
    },
  },
}) => {
  const [text, setText] = useState(popup?.text);
  const [vacationOn, setVacationOn] = useState(popup?.vacationOn);
  const [vacationDates, setVacationDates] = useState(popup?.vacationDates);
  const textAreaRef = useRef(null);
  useEffect(() => {
    setText(popup?.text);
    setVacationOn(popup?.vacationOn);
    setVacationDates(popup?.vacationDates);
  }, [popup]);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [text, editOn]);
  if (editOn)
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "30px",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <input
                style={{ width: "15px", height: "15px" }}
                onChange={(e) => {
                  setVacationOn(!vacationOn);
                  setVacationDates({
                    start: null,
                    end: null,
                  });
                }}
                checked={!vacationOn}
                type="checkbox"
              ></input>
              <span>Графік роботи</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <input
                style={{ width: "15px", height: "15px" }}
                onChange={(e) => {
                  setVacationOn(!vacationOn);
                }}
                checked={vacationOn}
                type="checkbox"
              ></input>
              <span>Відпустка</span>
            </div>
          </div>
          {vacationOn && (
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span>Початок:</span>
              <input
                type="date"
                onChange={(e) => {
                  setVacationDates({ ...vacationDates, start: e.target.value });
                }}
                value={vacationDates?.start}
              ></input>
              <span>Кінець:</span>
              <input
                type="date"
                onChange={(e) => {
                  setVacationDates({ ...vacationDates, end: e.target.value });
                }}
                value={vacationDates?.end}
              ></input>
            </div>
          )}
        </div>
        <textarea
          ref={textAreaRef}
          onChange={(e) => setText(e.target.value)}
          value={text}
        ></textarea>
        <div style={{ display: "flex", gap: "20px" }}>
          <button
            onClick={async () => {
              await adminAPI.editInfo({
                popup: {
                  text,
                  vacationOn,
                  vacationDates,
                },
              });
              getInfo();
              setEditOn(false);
            }}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Зберегти
          </button>
          <button
            onClick={() => setEditOn(false)}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Скасувати
          </button>
        </div>
      </div>
    );
  return (
    <div
      style={{
        background: "#fdf2cc",
        padding: "10px 40px",
        borderLeft: "10px solid rgb(255, 140, 56)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <div style={{ width: "30px", height: "30px", marginRight: "25px" }}>
        <svg
          style={{ color: "rgb(255, 140, 56)" }}
          class="b-info-banner__icon"
          viewBox="0 0 24 24"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill="currentColor" fillRule="nonzero">
              <path d="M10.9084921,2.78216375 C11.9482635,2.20625071 13.2462954,2.54089334 13.8841246,3.52240345 L13.9669228,3.66023048 L21.725879,17.6684928 C21.9106772,18.0021332 22.0076302,18.3772743 22.0076302,18.7586748 C22.0076302,19.9495388 21.0824686,20.9243197 19.911679,21.003484 L19.7576302,21.0086748 L4.24187791,21.0086748 C3.86056733,21.0086748 3.48551176,20.9117674 3.15192815,20.7270522 C2.11208877,20.1512618 1.7070482,18.8734454 2.20060302,17.8120352 L2.27350053,17.668725 L10.0302967,3.66046274 C10.2348245,3.291099 10.5391525,2.98673515 10.9084921,2.78216375 Z M12.0004478,16.0017852 C11.4489016,16.0017852 11.0017852,16.4489016 11.0017852,17.0004478 C11.0017852,17.5519939 11.4489016,17.9991104 12.0004478,17.9991104 C12.5519939,17.9991104 12.9991104,17.5519939 12.9991104,17.0004478 C12.9991104,16.4489016 12.5519939,16.0017852 12.0004478,16.0017852 Z M11.9982876,7.99806092 C11.4854518,7.99824566 11.0629195,8.38443798 11.0053334,8.88179743 L10.9986476,8.99842097 L11.0004479,13.9993002 L11.0072176,14.1159189 C11.0651617,14.6132367 11.487972,14.9991248 12.0008078,14.9989403 C12.5136436,14.9987555 12.9361759,14.6125632 12.993762,14.1152038 L13.0004478,13.9985802 L12.9986476,8.99770102 L12.9918779,8.88108232 C12.9339337,8.38376446 12.5111234,7.99787644 11.9982876,7.99806092 Z"></path>
            </g>
          </g>
        </svg>
      </div>
      <div>
        <p>{text}</p>
      </div>
      <div
        className={s.content19}
        style={{ position: "absolute", right: "10px" }}
      ></div>
    </div>
  );
};

export default PopupAdmin;
