import React from "react";
import Caption from "../caption";
import s from "../../styles/general/overlay.module.css";

const DescriptionAdmin = ({
  data = "",
  captionText = "",
  productDescription = false,
  setEditOn = () => {},
  deleteOn = false,
  deleteDescription = async () => {},
  getCategory = () => {},
}) => {
  return (
    <div>
      {captionText && !productDescription && (
        <Caption text={captionText}></Caption>
      )}
      {data !== "" ? (
        <div className={s.container} style={{ position: "relative" }}>
          <div
            className={s.child}
            style={{ background: "white", minHeight: "30px" }}
          >
            {data.split("\n\n").map((text, index) => {
              return (
                <div
                  style={{
                    margin: productDescription ? "10px 0" : "20px 0 0 0",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {text.split("\n").map((substring) => (
                    <span
                      style={{
                        fontWeight:
                          productDescription && index === 0 ? "600" : "400",
                      }}
                    >
                      {substring}
                    </span>
                  ))}
                </div>
              );
            })}
          </div>
          <div
            style={{
              opacity: "0",
              width: "100%",
              height: "100%",
              display: "flex",
              position: "absolute",
              top: "0",
              zIndex: "2",
              alignItems: "center",
              justifyContent: "center",
              boxSizing: "border-box",
            }}
          >
            <button
              onClick={() => setEditOn(true)}
              style={{
                background: "none",
                height: "fit-content",
                fontSize: "16px",
                fontWeight: "600",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
                color: "white",
                border: "2px solid white",
                borderRadius: "10px",
                padding: "8px",
              }}
            >
              Edit
            </button>
            {deleteOn && (
              <button
                onClick={async () => {
                  await deleteDescription();
                  getCategory();
                }}
                style={{
                  background: "none",
                  height: "fit-content",
                  fontSize: "16px",
                  fontWeight: "600",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                  color: "white",
                  border: "2px solid white",
                  borderRadius: "10px",
                  padding: "8px",
                  marginLeft: "10px",
                }}
              >
                Del
              </button>
            )}
          </div>
        </div>
      ) : (
        <div
          className={s.container}
          style={{ position: "relative", height: "30px" }}
        >
          <div
            className={s.child}
            style={{ background: "white", height: "100%" }}
          ></div>
          <div
            style={{
              opacity: "0",
              width: "100%",
              height: "100%",
              display: "flex",
              position: "absolute",
              top: "0",
              zIndex: "2",
              alignItems: "center",
              justifyContent: "center",
              boxSizing: "border-box",
            }}
          >
            <button
              onClick={() => setEditOn(true)}
              style={{
                background: "none",
                height: "fit-content",
                fontSize: "16px",
                fontWeight: "600",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
                color: "white",
                border: "2px solid white",
                borderRadius: "10px",
                padding: "8px",
              }}
            >
              Add
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DescriptionAdmin;
