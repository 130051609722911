import React, { useEffect, useState } from "react";
import s from "../styles/general/icons.module.css"; // Для іконок
import styles from "../styles/Products.module.css"; // Імпорт стилів для компонентів
import { useLocation } from "react-router-dom";
import Flag from "./flag";
import Pagination from "./pagination";
import { publicAPI } from "../api/api";
import HOST from "../env";
import Sort from "./sort";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";
import Caption from "./caption";

const Products = ({
  categoryId = "",
  cart = [],
  minPrice = "",
  maxPrice = "",
  filterValue = false,
  setCart = () => {},
  setFilterOn = () => {},
  phone = "",
}) => {
  const location = useLocation().pathname.split("/");
  let _id, query;
  if (location[location.length - 2] === "search_results")
    query = location[location.length - 1];
  else _id = location[location.length - 1];

  const pageSizes = [4, 8, 12, 16, 32, 64];
  const [products, setProducts] = useState([]);
  const [pageSize, setPageSize] = useState(pageSizes[pageSizes.length - 1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [sort, setSort] = useState("title");
  const [order, setOrder] = useState("1");

  const getProducts = async (query = "") => {
    try {
      const res = await publicAPI.getProducts(
        "",
        `&filter=isAvailable&filterValue=${
          filterValue === true ? filterValue : ""
        }&minPrice=${minPrice}&maxPrice=${maxPrice}&categoryId=${categoryId}&currentPage=${currentPage}&pageSize=${pageSize}&sort=${sort}&order=${order}&${query}`
      );
      setProducts([...res.data.products]);
      setTotalCount(res.data.totalCount);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 426)
      setPageSize(pageSizes[pageSizes.length - 2]);
  }, []);

  useEffect(() => {
    if (query) {
      getProducts("title=" + query);
      return;
    }
    if (categoryId !== _id) return;
    setFilterOn(true);
    getProducts();
    return () => {
      setFilterOn(false);
    };
  }, [
    currentPage,
    pageSize,
    sort,
    order,
    categoryId,
    minPrice,
    maxPrice,
    filterValue,
    query,
  ]);

  return (
    <div className={query ? styles.padding : ""}>
      {query && (
        <Caption
          text={`Результати пошуку за запитом '${decodeURIComponent(query)}'`}
          margin="0 0 30px 0"
        ></Caption>
      )}
      <div className={styles.topControls}>
        <Sort setSort={setSort} setOrder={setOrder}></Sort>
        <Pagination
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        ></Pagination>
      </div>
      <div className={styles.productGrid}>
        {products.map((product) => (
          <ProductPreview
            {...product}
            cart={cart}
            setCart={setCart}
            phone={phone}
          ></ProductPreview>
        ))}
      </div>
      <div className={styles.bottomControls}>
        <div className={styles.pageSizeSelect}>
          <span>Товарів на сторінці: </span>
          <select
            onChange={(e) => {
              const value = e.target.value;
              setPageSize(+value);
              window.scrollTo(0, 0);
            }}
            className={styles.select}
          >
            {pageSizes.map((size) => (
              <option selected={pageSize === size ? true : false} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <Pagination
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        ></Pagination>
      </div>
    </div>
  );
};

const ProductPreview = ({
  title = "",
  imageURL = "",
  price = "",
  discountPrice = "",
  discountPeriod = { start: null, end: null },
  wholesalePrices = [],
  isAvailable = "",
  toOrder = { on: false, dayCount: null },
  code = "",
  _id = "",
  flag = "",
  cart = [],
  setCart = () => {},
  phone = "",
}) => {
  const [showWholesale, setShowWholesale] = useState(false);
  const isPeriodValid = () => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };

  const navigate = useConditionalNavigate();

  return (
    <div className={styles.productPreview}>
      {flag && <Flag text={flag}></Flag>}
      <div className={styles.imageContainer}>
        <img
          onClick={(e) => navigate("/product/" + _id, e)}
          src={`${HOST}/${imageURL}`}
          className={styles.image}
        ></img>
      </div>

      <div className={styles.productDetails}>
        {code && <span className={styles.code}>Код: {code}</span>}
        <span
          onClick={(e) => navigate("/product/" + _id, e)}
          className="text_hover"
        >
          {title}
        </span>
        {isAvailable ? (
          <span className={styles.available}>В наявності</span>
        ) : toOrder?.on && toOrder?.dayCount ? (
          <span className={styles.toOrder}>
            Під замовлення ({toOrder?.dayCount} днів)
          </span>
        ) : (
          <span className={styles.outOfStock}>Немає в наявності</span>
        )}
        {(!discountPrice || !isPeriodValid()) && (
          <span className={styles.price}>{price + " ₴"}</span>
        )}
        {discountPrice && isPeriodValid() && (
          <>
            <span className={styles.discountPeriod}>
              {discountPeriod.start + " - " + discountPeriod.end}
            </span>
            <div className={styles.discountedPriceContainer}>
              <span className={styles.oldPrice}>
                {price}
                <span className={styles.oldPriceLine}></span>
              </span>
              <span className={styles.newPrice}>{discountPrice + " ₴"}</span>
            </div>
          </>
        )}
        {wholesalePrices.length > 0 && (
          <span
            className={styles.wholesaleToggle}
            onClick={() => setShowWholesale(!showWholesale)}
          >
            Оптові ціни &#x25BE;
          </span>
        )}
        {wholesalePrices.length > 0 &&
          showWholesale &&
          wholesalePrices.map((entity) => (
            <span className={styles.wholesaleItem}>
              від {entity.count} - {entity.price} / од
            </span>
          ))}
        {isAvailable || (toOrder?.on && toOrder.dayCount) ? (
          cart.filter((product) => product.title === title).length > 0 ? (
            <button className={styles.inCartButton} disabled={true}>
              В корзині
            </button>
          ) : (
            <button
              className={styles.addToCartButton}
              onClick={() => {
                let index;
                cart.find((product, i) => {
                  if (product.title === title) index = i;
                });
                const newCart = [...cart];
                if (index !== undefined) {
                  newCart[index].count += 1;
                  setCart(newCart);
                } else {
                  newCart.push({
                    _id,
                    title,
                    imageURL,
                    isAvailable,
                    price,
                    discountPrice,
                    discountPeriod,
                    wholesalePrices,
                    count: 1,
                  });
                  setCart(newCart);
                }
              }}
            >
              Купити
            </button>
          )
        ) : (
          <span className={s.content9}>{phone}</span>
        )}
      </div>
    </div>
  );
};

export default Products;
