import { useEffect, useRef, useState } from "react";
import ButtonControls from "./button_controls";
import Caption from "./caption";
import HOST from "../env";
import s from "../styles/general/animations.module.css"; // Імпорт анімацій
import styles from "../styles/Reccomendations.module.css"; // Імпорт нового модуля стилів
import { publicAPI } from "../api/api";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";

const Reccomendations = ({ captionText = "", relatedProducts = [] }) => {
  const [products, setProducts] = useState([]);
  
  const getProducts = async (query = "") => {
    try {
      const res = await publicAPI.getProducts("", `${query}`);
      setProducts(res.data.products);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (captionText === "Ми рекомендуємо")
      getProducts(`filter=recommended&filterValue=true`);
    else if (captionText === "Ви переглядали") {
      const viewedProducts =
        JSON.parse(localStorage.getItem("viewedProducts") || null) || [];
      viewedProducts.length !== 0 &&
        getProducts(
          `filter=_ids&filterValue=${viewedProducts.map(
            (product) => product._id
          )}`
        );
    } else if (
      captionText === "З цим товаром купують" &&
      relatedProducts.length > 0
    ) {
      setProducts(relatedProducts);
    }
  }, []);

  const containerRef = useRef(null);

  if (products.length === 0) return;

  return (
    <div className={styles.recommendationsContainer}>
      <div className={styles.header}>
        <Caption margin="0" text={captionText}></Caption>
        <ButtonControls containerRef={containerRef}></ButtonControls>
      </div>
      <div
        className={styles.scrollBlock}
        ref={containerRef}
      >
        {products.map((item) => (
          <Recomendation {...item} key={item._id}></Recomendation>
        ))}
      </div>
    </div>
  );
};

const Recomendation = ({ _id = "", title = "", imageURL = "", price = "" }) => {
  const navigate = useConditionalNavigate();
  return (
    <div className={styles.recommendationItem}>
      <div className={`${s.vertigo} ${styles.imageContainer}`}>
        <img
          onClick={(e) => navigate("/product/" + _id, e)}
          className={styles.image}
          src={`${HOST}/${imageURL}`}
        ></img>
      </div>
      <div className={styles.details}>
        <span
          onClick={(e) => navigate("/product/" + _id, e)}
          className="text_hover"
        >
          {title}
        </span>
        <span className={styles.priceTag}>
          {price + " ₴"}
        </span>
      </div>
    </div>
  );
};

export default Reccomendations;
