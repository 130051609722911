import React, { useEffect, useState } from "react";
import s from "../../styles/general/icons.module.css";
import { adminAPI } from "../../api/api";
import ConfirmDeleteModal from "./confirm_delete_modal";
import Pagination from "../pagination";

const FeedbacksPageAdmin = () => {
  const [comments, setComments] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(() => {});
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const getComments = async () => {
    try {
      const res = await adminAPI.getComments(
        `pageSize=${pageSize}&currentPage=${currentPage}`
      );
      setComments(res.data.comments);
      setTotalCount(res.data.totalCount);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getComments();
  }, [currentPage]);
  return (
    <div style={{ padding: "20px" }}>
      <div>
        <Pagination
          totalCount={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        ></Pagination>
      </div>
      <ConfirmDeleteModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={onConfirm}
      ></ConfirmDeleteModal>
      {comments.length > 0 &&
        comments.map((comment) => (
          <Feedback
            {...comment}
            getComments={getComments}
            setIsOpen={setIsOpen}
            setOnConfirm={setOnConfirm}
          ></Feedback>
        ))}
    </div>
  );
};

const Feedback = ({
  _id,
  customerName = "",
  starCount = 0,
  phone = "",
  date = "",
  date2 = "",
  feedback = "",
  vendorComment = "",
  approved = false,
  getComments = () => {},
  setIsOpen = () => {},
  setOnConfirm = () => {},
}) => {
  const [isCommenting, setIsCommenting] = useState(false);
  const [VendorComment, setVendorComment] = useState(vendorComment);
  const starIndexes = [1, 2, 3, 4, 5];
  return (
    <div>
      {!isCommenting ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f7f7f7",
            border: "1px solid rgb(222, 222, 222)",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              padding: "15px",
            }}
          >
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span className={s.content21}></span>
              <span style={{ fontWeight: "500" }}>{customerName}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <span>
                {starCount === 0
                  ? "Жахливо"
                  : starCount === 1
                  ? "Погано"
                  : starCount === 2
                  ? "Недуже"
                  : starCount === 3
                  ? "Добре"
                  : starCount === 4
                  ? "Відмінно"
                  : "Бездоганно"}
              </span>
              <div style={{ display: "flex", alignItems: "center" }}>
                {starIndexes.map((i) => (
                  <StarIcon
                    fill={i <= starCount ? "yellow" : "white"}
                  ></StarIcon>
                ))}
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span className={s.content22}></span>
              <span>{date}</span>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <span style={{ fontSize: "18px" }} className={s.content2}></span>
              <span>{phone}</span>
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px", padding: "10px" }}>
            <div
              style={{
                width: "50%",
                padding: "10px",
                background: "white",
                boxSizing: "border-box",
              }}
            >
              <span style={{ wordWrap: "break-word" }}>{feedback}</span>
            </div>
            {date2 && vendorComment && (
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{ display: "flex", gap: "20px", alignItems: "center" }}
                >
                  <span style={{ fontWeight: "500", wordWrap: "break-word" }}>
                    Коментар продавця
                  </span>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <span className={s.content22}></span>
                    <span>{date2}</span>
                  </div>
                </div>
                <span>{vendorComment}</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <textarea
          onChange={(e) => {
            setVendorComment(e.target.value);
          }}
          rows={6}
          style={{ width: "50%" }}
          value={VendorComment}
        ></textarea>
      )}
      <div style={{ display: "flex", gap: "20px" }}>
        {approved ? (
          <button
            onClick={async () => {
              try {
                await adminAPI.changeComment({ _id, approved: false });
                getComments();
              } catch (e) {
                console.error(e);
              }
            }}
            style={{
              padding: "5px",
              margin: "10px 0",
              background: "red",
              width: "fit-content",
              color: "white",
              fontSize: "16px",
              border: "none",
              borderRadius: "3px",
            }}
          >
            Приховати
          </button>
        ) : (
          <button
            onClick={async () => {
              try {
                await adminAPI.changeComment({ _id, approved: true });
                getComments();
              } catch (e) {
                console.error(e);
              }
            }}
            style={{
              padding: "5px",
              margin: "10px 0",
              background: "rgb(106, 180, 22)",
              width: "fit-content",
              color: "white",
              fontSize: "16px",
              border: "none",
              borderRadius: "3px",
            }}
          >
            Опублікувати
          </button>
        )}
        {isCommenting ? (
          <button
            onClick={async () => {
              setIsCommenting(false);
              try {
                await adminAPI.changeComment({
                  _id,
                  vendorComment: VendorComment,
                  date2: VendorComment
                    ? new Date().toLocaleString().split(",")[0]
                    : "",
                });
                getComments();
              } catch (e) {
                console.error(e);
              }
            }}
            style={{
              padding: "5px",
              margin: "10px 0",
              background: "rgb(106, 180, 22)",
              width: "fit-content",
              color: "white",
              fontSize: "16px",
              border: "none",
              borderRadius: "3px",
            }}
          >
            Зберегти
          </button>
        ) : (
          <button
            onClick={() => {
              setIsCommenting(true);
            }}
            style={{
              padding: "5px",
              margin: "10px 0",
              background: "rgb(106, 180, 22)",
              width: "fit-content",
              color: "white",
              fontSize: "16px",
              border: "none",
              borderRadius: "3px",
            }}
          >
            Коментувати
          </button>
        )}
        <button
          onClick={() => {
            setIsOpen(true);
            setOnConfirm(() => async () => {
              try {
                await adminAPI.changeComment({ _id, remove: true });
                getComments();
              } catch (err) {
                console.log(err);
              }
            });
          }}
          style={{
            padding: "5px",
            margin: "10px 0",
            background: "red",
            width: "fit-content",
            color: "white",
            fontSize: "16px",
            border: "none",
            borderRadius: "3px",
          }}
        >
          Видалити
        </button>
      </div>
    </div>
  );
};

const StarIcon = ({ fill = "white" }) => {
  return (
    <svg
      width="18px"
      height="18px"
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="star-icon"
    >
      <path
        fill={fill}
        stroke="black"
        strokeWidth="2"
        d="M11.985,18.136 L5.187,23.000 L7.536,14.988 L1.000,9.562 L8.950,9.493 L11.985,1.000 L14.936,9.562 L23.000,9.562 L16.433,15.040 L18.783,23.000 L11.985,18.136 L11.985,18.136 Z"
      />
    </svg>
  );
};

export default FeedbacksPageAdmin;
