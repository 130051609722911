import React, { useEffect, useState } from "react";
import { publicAPI } from "../../api/api";
import HOST from "../../env";

const InvoicePDF = React.forwardRef(({ order }, ref) => {
  const [info, setInfo] = useState({});

  const isPeriodValid = (discountPeriod) => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };

  useEffect(() => {
    const getInfo = async () => {
      try {
        const res = await publicAPI.getInfo();
        setInfo(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getInfo();
  }, []);

  return (
    <div
      ref={ref}
      style={{
        padding: "20px",
        fontFamily: "Arial, sans-serif",
        border: "1px solid #ddd",
        borderRadius: "8px",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "#f9f9f9",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "2px solid #000",
          paddingBottom: "10px",
          marginBottom: "20px",
          flexWrap: "wrap",
        }}
      >
        <span style={{ color: "blue", fontSize: "18px", fontWeight: "bold" }}>
          Накладна №{order.orderNumber}
        </span>
        <span>{new Date().toLocaleDateString()}</span>
      </div>
      <div>
        <div
          style={{
            marginBottom: "20px",
            wordBreak: "break-word",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <h3 style={{ fontWeight: "bold" }}>Продавець:</h3>
          <p>{info?.contacts?.site}</p>
          <p>Адреса: {info?.contacts?.address}</p>
          <p>Телефон: {info?.contacts?.phone}</p>
          <p>Email: {info?.contacts?.email}</p>
        </div>
        <div
          style={{
            marginBottom: "20px",
            wordBreak: "break-word",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <h3 style={{ fontWeight: "bold" }}>Інформація про замовника:</h3>
          <p>Ім'я: {order?.details?.name}</p>
          <p>Телефон: {order?.details?.phone}</p>
        </div>
        <div
          style={{
            marginBottom: "20px",
            wordBreak: "break-word",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <h3 style={{ fontWeight: "bold" }}>Інформація про доставку:</h3>
          <p>Адреса: {order?.details?.settlement}</p>
          <p>Спосіб доставки: {order?.details?.postalService}</p>
          <p>Спосіб оплати: {order?.paymentType}</p>
          <p>Коментар: {order?.details?.comment || "Відсутній"}</p>
        </div>
      </div>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        {order?.details?.cart.map((obj, i) => {
          const { imageURL, title, code } = obj.product;
          let price;
          let entities = obj.product.wholesalePrices.filter(
            (entity) => entity.count <= obj.count
          );
          if (entities.length > 0) price = entities[entities.length - 1].price;
          else
            price =
              obj.product.discountPrice &&
              isPeriodValid(obj.product.discountPeriod)
                ? obj.product.discountPrice
                : obj.product.price;
          return (
            <div
              key={i}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
                borderBottom: "1px solid #ddd",
                paddingBottom: "10px",
                flexWrap: "nowrap",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", width: "70%" }}
              >
                <span style={{ marginRight: "10px" }}>{i + 1}.</span>
                <img
                  style={{
                    width: "88px",
                    height: "auto",
                    marginRight: "10px",
                    flexShrink: 0,
                  }}
                  src={`${HOST}/${imageURL}`}
                  alt={title}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <span style={{ color: "blue" }}>{title}</span>
                  <p>Код товару: {code}</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  textAlign: "right",
                }}
              >
                <span>Кількість: {obj.count}</span>
                <p style={{ fontWeight: "bold" }}>{price} ₴</p>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: "18px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <span style={{ marginRight: "10px", whiteSpace: "nowrap" }}>
          Всього:
        </span>
        <span style={{ fontSize: "20px", color: "#000", whiteSpace: "nowrap" }}>
          {order?.details?.sum} ₴
        </span>
      </div>
    </div>
  );
});

export default InvoicePDF;
