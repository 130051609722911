const FilterAdmin = ({
  setFilter = () => {},
  setFilterValue = () => {},
  setCurrentPage = () => {},
  selected = "",
  options = [
    { text: "Всі", selected: "" },
    { text: "В наявності", selected: "isAvailable_true" },
    { text: "Немає в наявності", selected: "isAvailable_false" },
    { text: "Під замовлення", selected: "toOrder.on_true" },
    { text: "Рекомендовано", selected: "recommended_true" },
    { text: "Знижка", selected: "discountPrice_exists" },
    { text: "Оптові ціни", selected: "wholesalePrices_exists" },
  ],
}) => {
  return (
    <div>
      <span style={{ marginRight: "10px" }}>Сортування: </span>
      <select
        onChange={(e) => {
          const value = e.target.value.split("_");
          setFilter(value[0]);
          setFilterValue(value[1]);
          setCurrentPage(1);
        }}
        style={{
          borderRadius: "50px",
          padding: "5px",
          fontSize: "14px",
          border: "1px solid #e8e8e8",
          outline: "none",
          boxShadow: "0 0px 2px rgba(0, 0, 0, 0.1)",
        }}
      >
        {options.map((option) => (
          <option
            selected={selected === option.selected}
            value={option.selected}
            style={{ fontSize: "14px" }}
          >
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterAdmin;
