import React from "react";
import s from "../styles/Sort.module.css";

const Sort = ({ setSort = () => {}, setOrder = () => {} }) => {
  return (
    <div className={s.sortContainer}>
      <span>Сортування: </span>
      <select
        onChange={(e) => {
          const value = e.target.value.split("_");
          setSort(value[0]);
          setOrder(value[1]);
        }}
        className={s.sortSelect}
      >
        <option value={"title_1"}>за порядком</option>
        <option value={"price_1"}>за зростанням ціни</option>
        <option value={"price_-1"}>за зниженням ціни</option>
      </select>
    </div>
  );
};

export default Sort;
