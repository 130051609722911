import { useEffect, useState } from "react";
import HOST from "../env";
import styles from "../styles/CartItem.module.css"; // Імпорт модуля стилів

const CartItem = ({
  title = "",
  imageURL = "",
  price = "",
  discountPrice = "",
  discountPeriod = { start: null, end: null },
  wholesalePrices = [],
  isAvailable = "",
  toOrder = {},
  count = 1,
  cart = [],
  setCart = () => {},
}) => {
  const isPeriodValid = () => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };

  const changeCount = (count) => {
    if (count < 1) return;
    let index;
    cart.find((product, i) => {
      if (product.title === title) index = i;
    });
    const newCart = [...cart];
    if (index !== undefined) {
      newCart[index].count = count;
      setCart(newCart);
    }
  };

  const deleteItemFromCart = () => {
    setCart(cart.filter((product) => product.title !== title));
  };

  const [Count, setCount] = useState(count);

  useEffect(() => {
    setCount(count);
  }, [count]);

  return (
    <div className={styles.cartItemContainer}>
      <div>
        <img className={styles.cartItemImage} src={`${HOST}/${imageURL}`} alt="Product"></img>
      </div>
      <div className={styles.cartItemDetails}>
        <div className={styles.cartItemHeader}>
          <span className={styles.cartItemTitle}>{title}</span>
          <div onClick={() => deleteItemFromCart()}>
            <svg
              className={styles.cartItemDeleteIcon}
              viewBox="0 0 24 24"
              fill="none"
              data-testid="SvgDelete"
              data-qaid="SvgDelete"
              focusable="false"
              aria-hidden="true"
              tabIndex="-1"
            >
              <path
                fill="currentColor"
                d="M12 3c.6 0 1 .4 1 1v1h6v3h1c.6 0 1 .4 1 1s-.4 1-1 1h-1v8c0 1.7-1.3 3-3 3H8c-1.7 0-3-1.3-3-3v-8H4c-.6 0-1-.4-1-1s.4-1 1-1h1V5h6V4c0-.5.4-.9.9-1h.1Zm5 7H7v8c0 .6.4 1 1 1h8c.6 0 1-.4 1-1v-8Zm-3 2c.6 0 1 .4 1 1v3c0 .6-.4 1-1 1s-1-.4-1-1v-3c0-.6.4-1 1-1Zm-4 0c.6 0 1 .4 1 1v3c0 .6-.4 1-1 1s-1-.4-1-1v-3c0-.6.4-1 1-1Zm7-5H7v1h10V7Z"
              ></path>
            </svg>
          </div>
        </div>
        {isAvailable ? (
          <span className={styles.availability}>В наявності</span>
        ) : toOrder?.on ? (
          <span className={styles.availability}>Під замовлення</span>
        ) : (
          <span className={styles.availability}>Немає в наявності</span>
        )}
        <span className={styles.price}>
          {discountPrice && isPeriodValid() ? discountPrice : price}
        </span>
        <div className={styles.countContainer}>
          <div>
            <button
              onClick={() => changeCount(Count - 1)}
              className={styles.counterButton}
            >
              -
            </button>
            <input
              onInput={(e) => {
                const value = e.target.value;
                if (value === "") setCount(value);
                else if (Number.isInteger(+value)) setCount(+value);
              }}
              onBlur={() => {
                if (Count === "" || !Number.isInteger(+Count)) setCount(count);
                else changeCount(Count);
              }}
              className={styles.counterInput}
              value={Count}
            ></input>
            <button
              onClick={() => changeCount(Count + 1)}
              className={styles.counterButton}
            >
              +
            </button>
          </div>
          <div className={styles.totalPrice}>
            {discountPrice && isPeriodValid()
              ? +discountPrice * count + " ₴"
              : wholesalePrices.filter((entity) => entity.count <= count).length > 0
              ? "опт"
              : +price * count + " ₴"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;