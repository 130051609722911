import { useEffect, useState } from "react";
import styles from "../styles/ButtonControls.module.css";
import s from "../styles/general/icons.module.css";

const ButtonControls = ({ containerRef }) => {
  const [disabledRight, setDisabledRight] = useState(false);
  const [disabledLeft, setDisabledLeft] = useState(true);

  useEffect(() => {
    if (containerRef.current.scrollWidth === containerRef.current.clientWidth) {
      setDisabledRight(true);
    }
  }, [containerRef?.current]);

  return (
    <div className={styles.controlContainer}>
      <button
        disabled={disabledLeft}
        className={`${styles.button} ${disabledLeft ? "" : "text_hover"} ${s.content10}`} // Використання styles.content10
        onClick={() => {
          const container = containerRef.current;
          setDisabledLeft(container.scrollLeft - 600 <= 0);
          setDisabledRight(false);
          container.scrollLeft -= 600;
        }}
      >
        Назад
      </button>
      <button
        disabled={disabledRight}
        className={`${styles.button} ${disabledRight ? "" : "text_hover"} ${s.content11}`} // Використання styles.content11
        onClick={() => {
          const container = containerRef.current;
          setDisabledRight(
            container.scrollLeft + 600 + container.offsetWidth >
              container.scrollWidth
          );
          setDisabledLeft(false);
          container.scrollLeft += 600;
        }}
      >
        Вперед
      </button>
    </div>
  );
};

export default ButtonControls;
