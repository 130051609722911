import React from "react";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";
import styles from "../styles/Header.module.css"; // Стилі тепер підключені з CSS modules
import MenuHeader from "./menuHeader";

const Header = ({
  minPrice = "",
  maxPrice = "",
  filterValue = false,
  setMinPrice = () => {},
  setMaxPrice = () => {},
  setFilterValue = () => {},
  cart = [],
  setCartOn = () => {},
}) => {
  const navigate = useConditionalNavigate();

  return (
    <div className={styles.headerContainer}>
      <header className={styles.header}>
        <MenuHeader
          minPrice={minPrice}
          maxPrice={maxPrice}
          filterValue={filterValue}
          setMinPrice={setMinPrice}
          setMaxPrice={setMaxPrice}
          setFilterValue={setFilterValue}
          setCartOn={setCartOn}
        ></MenuHeader>
        <div className={styles.logoSection}>
          <div onClick={(e) => navigate("/", e)} className={styles.logoWrapper}>
            <img
              className={styles.logoImage}
              src="/images/4532869852_w350_h100_coffee-ride.webp"
              alt="Coffee Ride"
            />
            <span className={styles.logoText}>Coffee Ride</span>
          </div>
        </div>
        <div className={styles.cartSection}>
          <div className={styles.cartIconWrapper}>
            <div className={styles.cartIcon}></div>
          </div>
          <div onClick={() => setCartOn(true)} className={styles.cartButton}>
            <div className={styles.cartImage}></div>
            <div className={styles.cartDetails}>
              <span className={styles.cartText}>Кошик</span>
              {cart.length > 0 && (
                <span className={styles.cartCount}>{cart.length}</span>
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
