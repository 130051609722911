import React, { useEffect, useState } from "react";

const SearchInputAdmin = ({
  setInputValue = () => {},
  setCurrentPage = () => {},
  inputValue = "",
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <input
        placeholder=""
        style={{
          width: "500px",
          padding: "10px",
          borderRadius: "100px",
          border: "2px solid #f5643e",
          width: "500px",
        }}
        onInput={(e) => {
          setInputValue(e.target.value);
          setCurrentPage(1)
        }}
        value={inputValue}
        onFocus={(e) => {
          e.preventDefault();
          e.target.style.outline = "none";
        }}
      ></input>
    </div>
  );
};

// export const filterProducts = (
//   e,
//   context = {},
//   setFilter = () => {},
//   setProducts = () => {}
// ) => {
//   const text = e.target.value;
//   const isValid = !/[\[\]\\]/.test(text);
//   if (text === "") setFilter([]);
//   else if (isValid) {
//     setFilter(
//       context
//         .filter((el) => new RegExp(text, "i").test(el.title))
//         .map((el) => el.title)
//     );
//   }
//   setProducts(context.filter((el) => new RegExp(text, "i").test(el.title)));
// };

export const SearchResultsAdmin = ({
  filter = [],
  setFilter = () => {},
  setValue = () => {},
  gap = "50px",
}) => {
  return (
    <>
      {filter.length > 0 && (
        <div
          style={{
            color: "black",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: gap,
            width: "520px",
            zIndex: "2",
            border: "1px solid gray",
            borderRadius: "5px",
          }}
        >
          {filter.map((text, index) => {
            return (
              index < 10 && (
                <span
                  onClick={() => {
                    setValue(text);
                    // setFilter([]);
                  }}
                  style={{ padding: "10px", borderBottom: "1px solid #f0f4fd" }}
                >
                  {text}
                </span>
              )
            );
          })}
        </div>
      )}
    </>
  );
};

export default SearchInputAdmin;
