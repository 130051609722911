import axios from "axios";
import HOST from "../env";

const instance = axios.create({
  withCredentials: true,
  baseURL: `${HOST}/api/`,
});

export const publicAPI = {
  getInfo() {
    return instance.get(`info`);
  },
  getProducts(_id = "", query = "") {
    return instance.get(`products/${_id}?${query}`);
  },
  getRange(_id = "", query = "") {
    return instance.get(`range/${_id}?${query}`);
  },
  getCategory(_id = "", query = "") {
    return instance.get(`categories/${_id}?${query}`);
  },
  getComments(query = "") {
    return instance.get(`comments?${query}`);
  },
  addComment(data) {
    return instance.post(`/add-comment`, data);
  },
  paymentInit(cart = {}, data = {}, query = "") {
    return instance.post(`payment-init?${query}`, { cart, data });
  },
};

export const adminAPI = {
  getComments(query = "") {
    return instance.get(`administrator/comments?${query}`);
  },
  getOrders(query = "") {
    return instance.get(`administrator/orders?${query}`);
  },
  editOrder(_id = "", newOrder) {
    return instance.post(`administrator/edit-order/${_id}`, newOrder);
  },
  changeComment(data) {
    return instance.post(`administrator/change-comment`, data);
  },
  addCategory(_id = "", newCategory) {
    return instance.post(`administrator/categories/add/${_id}`, newCategory);
  },
  deleteCategory(_id = "") {
    return instance.delete(`administrator/categories/delete/${_id}`);
  },
  editCategory(_id = "", formData) {
    return instance.put(`administrator/categories/edit/${_id}`, formData);
  },
  addProduct(product = {}) {
    return instance.post(`administrator/products/add`, product);
  },
  deleteProduct(_id = "") {
    return instance.delete(`administrator/products/delete/${_id}`);
  },
  editProductPreview(_id = "", formData) {
    return instance.put(`administrator/products/edit-preview/${_id}`, formData);
  },
  editProductDetails(_id = "", formData) {
    return instance.put(`administrator/products/edit-details/${_id}`, formData);
  },
  editInfo(data) {
    return instance.post(`administrator/edit-info/`, data);
  },
  login(formData) {
    return instance.post(`administrator/login`, formData);
  },
  validateToken() {
    return instance.get(`administrator/validate-token`);
  },
  exportExcel() {
    return instance.get(`administrator/export-excel`, {
      responseType: "blob",
    });
  },
  backup() {
    return instance.get(`administrator/backup`, {
      responseType: "blob",
    });
  },
};
