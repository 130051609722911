import { deleteCookie } from "../../App";
import s2 from "../../styles/general/animations.module.css";
import { useNavigate } from "react-router-dom";
import useConditionalNavigate from "../custom_hooks/use_conditional_navigate";
import { adminAPI } from "../../api/api";
import { useState } from "react";

const MenuAdmin = ({ setIsLogged = () => {} }) => {
  const navigate = useConditionalNavigate();
  const [isHidden, setIsHidden] = useState(true);
  const [isBlocked, setIsBlocked] = useState(false)
  return (
    <div
      style={{
        color: "#fff",
        backgroundColor: "#333",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "40px",
      }}
    >
      <div
        className={s2.parent}
        style={{
          listStyleType: "none",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <span
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            className={s2.menu}
            style={{ padding: "0 20px", borderRight: "1px dotted #fff" }}
            onClick={(e) => navigate("/administrator/categories", e)}
          >
            Категорії
          </a>
        </span>
        <span
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            className={s2.menu}
            style={{ padding: "0 20px", borderRight: "1px dotted #fff" }}
            onClick={(e) => navigate("/administrator/products", e)}
          >
            Товари
          </a>
        </span>
        <span
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            className={s2.menu}
            style={{ padding: "0 20px", borderRight: "1px dotted #fff" }}
            onClick={(e) => navigate("/administrator/feedback", e)}
          >
            Відгуки
          </a>
        </span>
        <span
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            className={s2.menu}
            style={{ padding: "0 20px", borderRight: "1px dotted #fff" }}
            onClick={(e) => navigate("/administrator/orders", e)}
          >
            Замовлення
          </a>
        </span>
        <span
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <a
            className={s2.menu}
            style={{
              padding: "0 20px",
              borderRight: "1px dotted #fff",
            }}
            onClick={(e) => navigate("/administrator/info", e)}
          >
            Інфо
          </a>
        </span>
        <span
          style={{
            position: "relative",
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
          onMouseEnter={() => setIsHidden(false)}
          onMouseLeave={() => setIsHidden(true)}
        >
          <a
            className={s2.menu}
            style={{
              padding: "0 20px",
              cursor: "pointer",
              borderRight: "1px dotted #fff",
            }}
          >
            Дамп
          </a>
          <div
            style={{
              display: isHidden ? "none" : "flex",
              flexDirection: "column",
              position: "absolute",
              top: "100%",
              left: 0,
              top: "40px",
              backgroundColor: "#333",
              padding: "5px 0",
              zIndex: 100,
              border: "1px solid #ccc",
            }}
          >
            <span
              style={{
                padding: "5px 20px",
              }}
            >
              <a
                className={s2.menu}
                style={{ color: "#fff", padding: "0", cursor: "pointer" }}
                onClick={async () => {
                  try {
                    if(isBlocked) return
                    setIsBlocked(true)
                    const response = await adminAPI.exportExcel();
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "exported_data.xlsx");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setIsBlocked(false)
                  } catch (error) {
                    setIsBlocked(false)
                    console.error("Помилка під час експорту:", error);
                  }
                }}
              >
                Excel
              </a>
            </span>

            <span
              style={{
                padding: "5px 20px",
              }}
            >
              <a
                className={s2.menu}
                style={{ color: "#fff", padding: "0", cursor: "pointer" }}
                onClick={async (e) => {
                  try {
                    if(isBlocked) return
                    setIsBlocked(true)
                    const response = await adminAPI.backup();
                    const contentDisposition =
                      response.headers["content-disposition"];
                    const matches =
                      contentDisposition.match(/filename="?([^"]+)"?/);
                    const filename = matches ? matches[1] : "dump.zip";
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setIsBlocked(false)
                  } catch (error) {
                    setIsBlocked(false)
                    console.error("Помилка під час експорту:", error);
                  }
                }}
              >
                Mongo
              </a>
            </span>
          </div>
        </span>
      </div>
      <div
        style={{
          margin: "0 20px",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          onClick={() => {
            deleteCookie("token");
            setIsLogged(false);
          }}
          className={s2.menu}
          style={{ cursor: "pointer" }}
        >
          Logout
        </span>
      </div>
    </div>
  );
};

export default MenuAdmin;
