import { useEffect } from "react";
import styles from "../styles/Caption.module.css";

const Caption = ({
  setFilterOn = () => {},
  text,
  paddingLeft = "0",
  margin = "0",
}) => {
  useEffect(() => {
    setFilterOn(true);
    return () => setFilterOn(false);
  }, [text]);

  return (
    <div style={{ margin, paddingLeft }}>
      <span className={styles.captionContainer}>
        <span className={styles.captionText}>{text[0]}</span>
        {text.slice(1)}
      </span>
    </div>
  );
};

export default Caption;