import React, { useState } from "react";
import s from "../../styles/general/overlay.module.css";

const Overlay2 = ({ children }) => {
    const [editOn, setEditOn] = useState(false)
    if (editOn) return <>
        {React.Children.map(children, (child) =>
            React.cloneElement(child, { editOn, setEditOn })
        )}
    </>
    return (
        <div
            className={s.container}
            style={{ display: "grid", position: "relative" }}
        >
            <div className={s.child} style={{ backgroundColor: "white" }}>
                {React.Children.map(children, (child) =>
                    React.cloneElement(child, { editOn, setEditOn })
                )}
            </div>
            <div
                style={{
                    opacity: "0",
                    display: "flex",
                    position: "absolute",
                    alignSelf: "center",
                    justifySelf: "center",
                    zIndex: "2",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                }}
            >
                <button
                    onClick={() => setEditOn(true)}
                    style={{
                        background: "none",
                        height: "fit-content",
                        fontSize: "16px",
                        fontWeight: "600",
                        backgroundColor: "transparent",
                        outline: "none",
                        cursor: "pointer",
                        color: "white",
                        border: "2px solid white",
                        borderRadius: "10px",
                        padding: "8px",
                    }}
                >
                    Edit
                </button>
            </div>
        </div>
    );
};

export default Overlay2;