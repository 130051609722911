import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Description from "./description";
import Specs from "./specs";
import ProductSidebar from "./product_sidebar";
import { publicAPI } from "../api/api";
import HOST from "../env";
import Reccomendations from "./recomendations";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";
import s from "../styles/general/icons.module.css";
import styles from "../styles/ProductDetails.module.css"; // Import module styles

const ProductDetails = ({
  setSidebarOn = () => {},
  cart = [],
  setCart = () => {},
  phone = "",
}) => {
  const [element, setElement] = useState("description");
  const location = useLocation().pathname.split("/");
  const _id = location[location.length - 1];
  const [product, setProduct] = useState({});
  const [zoomedPicture, setZoomedPicture] = useState("");
  const [path, setPath] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const navigate = useConditionalNavigate();

  const getProduct = async () => {
    try {
      const res = await publicAPI.getProducts(_id);
      setProduct(res.data.products[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getPath = async () => {
      const path = await publicAPI.getCategory(
        product?.category?._id,
        "path=true"
      );
      if (path) setPath(path.data.path);
    };
    if (product?.category?._id) getPath();
  }, [product]);

  useEffect(() => {
    getProduct();
  }, [_id]);

  useEffect(() => {
    setSidebarOn(false);
    return () => setSidebarOn(true);
  }, []);

  let viewedProducts =
    JSON.parse(localStorage.getItem("viewedProducts") || null) || [];
  viewedProducts = viewedProducts.filter((prod) => {
    return prod?.title !== product?.title;
  });
  if (Object.keys(product).length > 0) {
    viewedProducts.unshift(product);
    if (viewedProducts.length > 10) viewedProducts.splice(10);
    localStorage.setItem("viewedProducts", JSON.stringify(viewedProducts));
  }

  if (product)
    return (
      <>
        {zoomedPicture && (
          <ZoomedImage
            pictures={product?.pictures
              .map((pictures) => pictures.split("\n"))
              .flat()}
            zoomedPicture={zoomedPicture}
            setZoomedPicture={setZoomedPicture}
          />
        )}

        {path && (
          <div className={styles.breadcrumbContainer}>
            <div
              onClick={(e) => navigate("/", e)}
              style={{ cursor: "pointer" }}
            >
              <svg
                style={{
                  position: "relative",
                  top: "1px",
                  width: "14px",
                  height: "14px",
                }}
                baseProfile="full"
                version="1.1"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m15.316708,6.684536l-6.592088,-6.225863c-0.196815,-0.148935 -0.39563,-0.379723 -0.646938,-0.379723l-0.105259,0c-0.251308,0 -0.450145,0.290621 -0.646915,0.439806l-6.655034,6.168643c-0.758855,0.622327 -0.775762,1.072473 -0.477278,1.433993c0.150685,0.182885 0.187566,0.280145 0.819165,0.280145l1.197615,-0.000341l0,5.967352c0,1.070859 0.535543,1.552478 1.387499,1.552478l1.417132,0l0.836867,-0.004999l0,-5.271193c0,-0.582673 0.332865,-0.757673 0.753469,-0.757673l2.884484,0c0.374973,0 0.702362,0.158002 0.702362,0.787465l0,5.234607l0.630303,0.011817l1.625173,0c0.897177,0 1.38759,-0.540134 1.38759,-1.464376l0,-6.058021l1.162688,-0.000205c0.588582,0 0.602284,0.016452 0.796441,-0.185521c0.395516,-0.411582 0.15732,-1.107468 -0.477278,-1.52839z"></path>
              </svg>
              <span className={styles.breadcrumbDivider}>{">"}</span>
            </div>
            {path.map((part, i) => (
              <span
                key={i}
                onClick={(e) => navigate("/categories/" + part._id, e)}
                className={styles.breadcrumbItem}
              >
                {part.title} {">"}
              </span>
            ))}
            <span
              onClick={(e) => navigate("/product/" + product._id, e)}
              className={styles.breadcrumbItem}
            >
              {product.title}
            </span>
          </div>
        )}

        <div className={styles.mainWrapper}>
          <div className={styles.contentWrapper}>
            <div className={styles.imageContainer}>
              {product?.pictures?.length &&
                product.pictures.map((pictures) => {
                  if (pictures.length === 0) return;
                  pictures = pictures.split("\n");
                  return (
                    <div className={styles.pictureBlock}>
                      {pictures.map((picture) => (
                        <div
                          onClick={() => setZoomedPicture(picture)}
                          className={styles.pictureItem}
                          style={{
                            height: pictures.length > 1 ? "49%" : "100%",
                            backgroundImage: `url(${HOST}/${picture})`,
                          }}
                        ></div>
                      ))}
                    </div>
                  );
                })}
            </div>

            <div className={styles.menuContainer}>
              <div
                className={`${styles.menuItem} ${
                  element === "description" ? "borderTop" : ""
                }`}
                onClick={() => {
                  if (element === "description") setIsMenuOpen(!isMenuOpen);
                  else {
                    setElement("description");
                    setIsMenuOpen(true);
                  }
                }}
              >
                <i className={s.content16} style={{ marginBottom: "5px" }}></i>
                <span className={styles.menuButton}>Опис</span>
              </div>
              <div
                className={`${styles.menuItem} ${
                  element === "specs" ? "borderTop" : ""
                }`}
                onClick={() => {
                  if (element === "specs") setIsMenuOpen(!isMenuOpen);
                  else {
                    setElement("specs");
                    setIsMenuOpen(true);
                  }
                }}
              >
                <i className={s.content17} style={{ marginBottom: "5px" }}></i>
                <span className={styles.menuButton}>Характеристики</span>
              </div>
              <div
                className={`${styles.menuItem} ${
                  element === "orderInfo" ? "borderTop" : ""
                }`}
                onClick={() => {
                  if (element === "orderInfo") setIsMenuOpen(!isMenuOpen);
                  else {
                    setElement("orderInfo");
                    setIsMenuOpen(true);
                  }
                }}
              >
                <i className={s.content18} style={{ marginBottom: "5px" }}></i>
                <span className={styles.menuButton}>
                  Інформація про замовлення
                </span>
              </div>
            </div>

            {element === "description" && isMenuOpen ? (
              <Description
                data={product?.description}
                productDescription={true}
                marginData="0 0 5px 0"
              />
            ) : element === "specs" && isMenuOpen ? (
              <Specs data={product.specs} />
            ) : (
              isMenuOpen &&
              element === "orderInfo" && (
                <div>
                  {product.orderInfo.split("\n\n").map((block) => {
                    return (
                      <div className={styles.infoContainer}>
                        {block.split("\n").map((substring) => {
                          const info = substring.split("\t");
                          return (
                            <div>
                              <span style={{ fontWeight: "600" }}>
                                {info[0] !== undefined ? info[0] : ""}
                              </span>
                              {info[1] !== undefined ? " " + info[1] : " "}
                              <span></span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              )
            )}
          </div>
          <ProductSidebar
            {...product}
            setCart={setCart}
            cart={cart}
            phone={phone}
          />
        </div>

        {product?.relatedProducts?.length > 0 && (
          <Reccomendations
            captionText={"З цим товаром купують"}
            relatedProducts={product.relatedProducts}
          />
        )}
      </>
    );
};

const ZoomedImage = ({
  pictures = [],
  zoomedPicture = "",
  setZoomedPicture = () => {},
}) => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    return () =>
      (document.getElementsByTagName("body")[0].style.overflow = "auto");
  }, []);

  if (zoomedPicture === "") return null;

  return (
    <div className={styles.zoomedModal}>
      <div className={styles.zoomedContainer}>
        <button
          onClick={() => setZoomedPicture("")}
          className={styles.closeButton}
        >
          ×
        </button>
        <button
          className={styles.navButton}
          onClick={() => {
            const index = pictures.indexOf(zoomedPicture);
            if (index >= 0 && index - 1 >= 0)
              setZoomedPicture(pictures[index - 1]);
          }}
        >
          &#8249;
        </button>
        <div
          className={styles.zoomedImage}
          style={{
            backgroundImage: `url(${HOST}/${zoomedPicture})`,
          }}
        ></div>
        <button
          className={styles.navButton}
          onClick={() => {
            const index = pictures.indexOf(zoomedPicture);
            if (index >= 0 && index + 1 < pictures.length)
              setZoomedPicture(pictures[index + 1]);
          }}
        >
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default ProductDetails;
