import s from "../styles/general/icons.module.css";
import s2 from "../styles/general/animations.module.css";
import styles from "../styles/MenuHeader.module.css"; // Підключення стилів з нового файлу
import { useEffect, useState } from "react";
import { publicAPI } from "../api/api";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";
import Filter from "./filter";
import { useLocation } from "react-router-dom";

const MenuHeader = ({
  minPrice = "",
  maxPrice = "",
  filterValue = false,
  setMinPrice = () => {},
  setMaxPrice = () => {},
  setFilterValue = () => {},
  setCartOn = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [categories, setCategories] = useState([]);
  const navigate = useConditionalNavigate();
  const isCategoryPath = useIsCategoryPath();
  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await publicAPI.getCategory();
        setCategories(res.data.categories);
      } catch (err) {
        console.log(err);
      }
    };
    getCategory();
  }, []);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}>
      <div className={styles.menuIcon} onClick={toggleMenu}>
        &#9776;
      </div>
      <nav className={styles.menuContent}>
        <a
          className={styles.aHover}
          onClick={(e) => {
            navigate("/", e);
            setIsOpen(false);
          }}
        >
          Головна
        </a>
        <a
          className={`${isHidden ? styles.aHover : ""}`}
          onClick={() => setIsHidden(!isHidden)}
        >
          Товари та послуги
          {!isHidden && (
            <div className={styles.submenuList}>
              {categories.map((category) => (
                <a
                  className={s2.submenu + " " + styles.submenuItem}
                  onClick={(e) => {
                    navigate("/categories/" + category._id, e);
                    setIsOpen(false);
                  }}
                  key={category._id}
                >
                  <a>{category.title}</a>
                </a>
              ))}
            </div>
          )}
        </a>
        <a
          className={styles.aHover}
          onClick={(e) => {
            navigate("/aboutus", e);
            setIsOpen(false);
          }}
        >
          Про нас
        </a>
        <a
          className={styles.aHover}
          onClick={(e) => {
            navigate("/contacts", e);
            setIsOpen(false);
          }}
        >
          Контакти
        </a>
        <a
          className={styles.aHover}
          onClick={(e) => {
            navigate("/delivery", e);
            setIsOpen(false);
          }}
        >
          Доставка та оплата
        </a>
        <a
          className={styles.aHover}
          onClick={(e) => {
            navigate("/return", e);
            setIsOpen(false);
          }}
        >
          Повернення та обмін
        </a>
        <a
          className={styles.aHover}
          onClick={(e) => {
            navigate("/feedback", e);
            setIsOpen(false);
          }}
        >
          Відгуки
        </a>
        <a
          className={styles.aHover}
          onClick={(e) => {
            setIsOpen(false);
            setCartOn(true)
          }}
        >
          Кошик
        </a>
      </nav>
      {isCategoryPath && (
        <div className={styles.filterWrapper}>
          <Filter
            minPrice={minPrice}
            maxPrice={maxPrice}
            filterValue={filterValue}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
            setFilterValue={setFilterValue}
            setIsOpen={setIsOpen}
          ></Filter>
        </div>
      )}
    </div>
  );
};

function useIsCategoryPath() {
  const location = useLocation();
  const [isCategoryPath, setIsCategoryPath] = useState(false);
  useEffect(() => {
    setIsCategoryPath(/^\/categories\/.*$/.test(location.pathname));
  }, [location.pathname]);
  return isCategoryPath;
}
export default MenuHeader;
