import React, { useEffect, useRef, useState } from "react";
import Caption from "../caption";
import { adminAPI } from "../../api/api";

const ReturnPolicyAdmin = ({
  returnPolicy = "",
  productDescription = false,
  editOn = false,
  setEditOn = () => {},
  getInfo = () => {},
}) => {
  const [text, setText] = useState(returnPolicy);
  useEffect(() => {
    setText(returnPolicy);
  }, [returnPolicy]);
  const textAreaRef = useRef(null);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [text, editOn]);
  if (editOn)
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <textarea
          value={text}
          ref={textAreaRef}
          onChange={(e) => setText(e.target.value)}
          style={{
            width: "100%",
          }}
        />
        <div style={{ display: "flex", gap: "20px" }}>
          <button
            onClick={async () => {
              await adminAPI.editInfo({
                returnPolicy: text,
              });
              getInfo();
              setEditOn(false);
            }}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Зберегти
          </button>
          <button
            onClick={() => setEditOn(false)}
            style={{
              border: "none",
              borderRadius: "5px",
              width: "fit-content",
              padding: "5px",
              backgroundColor: "rgb(106, 180, 22)",
              color: "white",
            }}
          >
            Скасувати
          </button>
        </div>
      </div>
    );
  return (
    <div
      style={{
        border: "0.5px solid rgb(193, 193, 193)",
        padding: "10px",
      }}
    >
      <Caption text={"Повернення та обмін"}></Caption>
      {returnPolicy.split("\n\n").map((text, index) => {
        return (
          <div
            style={{
              margin: returnPolicy ? "20px 0" : "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {text.split("\n").map((substring) => (
              <span
                style={{
                  fontWeight: productDescription && index === 0 ? "600" : "400",
                }}
              >
                {substring}
              </span>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default ReturnPolicyAdmin;
