import React from "react";
import Caption from "./caption";
import s from "../styles/general/icons.module.css";
import styles from "../styles/Delivery.module.css";

const Delivery = ({
  delivery = {
    deliveryWays: "",
    paymentWays: "",
    warranty: "",
  },
}) => {
  return (
    <div>
      <Caption text={"Умови доставки та оплати"}></Caption>
      
      <div className={styles.section}>
        <span className={`${s.content13} ${styles.title}`}>
          Способи доставки
        </span>
        {delivery?.deliveryWays?.split("\n\n").map((text, index) => (
          <div className={styles.textBlock} key={index}>
            {text.split("\n").map((substring, subIndex) => (
              <span key={subIndex}>{substring}</span>
            ))}
          </div>
        ))}
      </div>

      <div className={styles.section}>
        <span className={`${s.content14} ${styles.title}`}>
          Способи оплати
        </span>
        {delivery?.paymentWays?.split("\n\n").map((text, index) => (
          <div className={styles.textBlock} key={index}>
            {text.split("\n").map((substring, subIndex) => (
              <span key={subIndex}>{substring}</span>
            ))}
          </div>
        ))}
      </div>

      <div className={styles.section}>
        <span className={`${s.content13} ${styles.title}`}>
          Гарантія
        </span>
        {delivery?.warranty?.split("\n\n").map((text, index) => (
          <div className={styles.textBlock} key={index}>
            {text.split("\n").map((substring, subIndex) => (
              <span key={subIndex}>{substring}</span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Delivery;
