import Flag from "./flag";
import { useState } from "react";
import styles from "../styles/ProductSidebar.module.css"; // Імпортуємо модуль стилів
import s from "../styles/general/icons.module.css"; // Залишаємо для іконок

const ProductSidebar = ({
  _id = "",
  title = "",
  imageURL = "",
  price = "",
  discountPrice = "",
  discountPeriod = { start: null, end: null },
  wholesalePrices = [],
  isAvailable = "",
  toOrder = { on: false, dayCount: null },
  code = "",
  flag = "",
  cart = [],
  setCart = () => {},
  phone = "",
}) => {
  const [showWholesale, setShowWholesale] = useState(false);

  const isPeriodValid = () => {
    const startNumber = new Date(discountPeriod?.start).getTime();
    const endNumber = new Date(discountPeriod?.end).getTime();
    const nowNumber = new Date().getTime();
    return nowNumber < endNumber && endNumber - startNumber > 0;
  };

  return (
    <div className={styles.container}>
      <div className={styles.infoBox}>
        <h1 className={styles.title}>{title}</h1>
        {flag && <Flag text={flag} />}
        <div>
          {isAvailable ? (
            <span className={styles.inStock}>В наявності</span>
          ) : toOrder?.on && toOrder?.dayCount ? (
            <span className={styles.toOrder}>
              Під замовлення ({toOrder?.dayCount} днів)
            </span>
          ) : (
            <span className={styles.outOfStock}>Немає в наявності</span>
          )}
        </div>
        <div>
          <span className={styles.productCode}>{code && `Код: ${code}`}</span>
        </div>

        {(!discountPrice || !isPeriodValid()) && (
          <span className={styles.priceBox}>{price + " ₴"}</span>
        )}

        {discountPrice && isPeriodValid() && (
          <>
            <span className={styles.discountPeriod}>
              {discountPeriod.start + " - " + discountPeriod.end}
            </span>
            <div className={styles.discountedPriceContainer}>
              <span className={styles.oldPrice}>
                {price}
                <span className={styles.oldPriceLine}></span>
              </span>
              <span className={styles.newPrice}>{discountPrice + " ₴"}</span>
            </div>
          </>
        )}

        {wholesalePrices.length > 0 && (
          <span
            className={styles.wholesaleToggle}
            onClick={() => setShowWholesale(!showWholesale)}
          >
            Оптові ціни &#x25BE;
          </span>
        )}

        {wholesalePrices.length > 0 &&
          showWholesale &&
          wholesalePrices.map((entity) => (
            <span className={styles.wholesaleItem}>
              від {entity.count} - {entity.price} / од
            </span>
          ))}

        {isAvailable || (toOrder?.on && toOrder.dayCount) ? (
          cart.filter((product) => product.title === title).length > 0 ? (
            <button className={styles.inCartButton} disabled={true}>
              В корзині
            </button>
          ) : (
            <button
              className={styles.addToCartButton}
              onClick={() => {
                let index;
                cart.find((product, i) => {
                  if (product.title === title) index = i;
                });
                const newCart = [...cart];
                if (index !== undefined) {
                  newCart[index].count += 1;
                  setCart(newCart);
                } else {
                  newCart.push({
                    _id,
                    title,
                    imageURL,
                    isAvailable,
                    price,
                    discountPrice,
                    discountPeriod,
                    wholesalePrices,
                    count: 1,
                  });
                  setCart(newCart);
                }
              }}
            >
              Купити
            </button>
          )
        ) : (
          <></>
        )}

        <div>
          <span className={s.content9}>
            {phone.slice(0, 4) +
              " (" +
              phone.slice(4, 6) +
              ") " +
              phone.slice(6, 9) +
              "-" +
              phone.slice(9, 11) +
              "-" +
              phone.slice(11, 13)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductSidebar;
