import Caption from "./caption";
import styles from "../styles/Contacts2.module.css";
import s from "../styles/general/icons.module.css";

const Contacts2 = ({
  contacts = {
    company: "",
    contactPerson: "",
    phone: "",
    site: "",
    email: "",
    telegram: "",
    viber: "",
    address: "",
  },
}) => {
  return (
    <div className={styles.contactsContainer}>
      <Caption text={"Контакти"}></Caption>
      <div>
        <div className={styles.contactRow}>
          <span className={styles.label}>Компанія</span>
          <span>{contacts?.company}</span>
        </div>
        <div className={styles.contactRow}>
          <span className={styles.label}>Контактна особа</span>
          <span>{contacts?.contactPerson}</span>
        </div>
        <div className={styles.contactRow}>
          <span className={styles.label}>Телефон</span>
          <span>
            {contacts?.phone?.slice(0, 4) +
              " (" +
              contacts?.phone?.slice(4, 6) +
              ") " +
              contacts?.phone?.slice(6, 9) +
              "-" +
              contacts?.phone?.slice(9, 11) +
              "-" +
              contacts?.phone?.slice(11, 13)}
          </span>
        </div>
        <div className={styles.contactRow}>
          <span className={styles.label}>Сайт</span>
          <span className={s.content12 + " " + styles.link}>{contacts?.site}</span>
        </div>
        <div className={styles.contactRow}>
          <span className={styles.label}>Email</span>
          <span className={s.content12 + " " + styles.link}>{contacts?.email}</span>
        </div>
        <div className={styles.contactRow}>
          <span className={styles.label}>Telegram</span>
          <span>{contacts?.telegram}</span>
        </div>
        <div className={styles.contactRow}>
          <span className={styles.label}>Viber</span>
          <span>{contacts?.viber}</span>
        </div>
        <div className={styles.contactRow}>
          <span className={styles.label}>Адреса</span>
          <span className={styles.address}>{contacts?.address}</span>
        </div>
      </div>
    </div>
  );
};

export default Contacts2;
