import React from "react";

const ConfirmDeleteModal = ({
  isOpen = false,
  setIsOpen = () => {},
  onConfirm = () => {},
  itemName = "товар/категорію",
}) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          width: "350px",
          maxWidth: "90%",
          textAlign: "center",
        }}
      >
        <h3 style={{ marginBottom: "20px" }}>Підтвердження видалення</h3>
        <p style={{ marginBottom: "30px" }}>
          Ви дійсно хочете видалити {itemName}?
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button
            style={{
              padding: "10px 20px",
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              flex: 1,
            }}
            onClick={() => {
              onConfirm();
              setIsOpen(false);
            }}
          >
            Видалити
          </button>
          <button
            style={{
              padding: "10px 20px",
              backgroundColor: "#ccc",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              marginLeft: "10px",
              flex: 1,
            }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Скасувати
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
