import React, { useState } from "react";
import s from "../styles/general/icons.module.css";
import styles from "../styles/CreateFeedback.module.css";
import { publicAPI } from "../api/api";

const CreateFeedback = () => {
  const [customerName, setCustomerName] = useState("");
  const [phone, setPhone] = useState("");
  const [starCount, setStarCount] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [message, setMessage] = useState("");
  const [messageColor, setMessageColor] = useState("red");
  const starIndexes = [1, 2, 3, 4, 5];

  return (
    <div>
      <div className={styles.feedbackContainer}>
        <div className={styles.inputRow}>
          <div className={styles.inputContainer}>
            <span className={s.content21}></span>
            <input
              onChange={(e) => setCustomerName(e.target.value)}
              className={styles.input}
              value={customerName}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <span className={s.content2}></span>
            <input
              onChange={(e) => setPhone(e.target.value)}
              className={styles.input}
              value={phone}
            ></input>
          </div>
          <div className={styles.starRating}>
            <span className={styles.starLabel}>
              {starCount === 0
                ? "Жахливо"
                : starCount === 1
                ? "Погано"
                : starCount === 2
                ? "Недуже"
                : starCount === 3
                ? "Добре"
                : starCount === 4
                ? "Відмінно"
                : "Бездоганно"}
            </span>
            <div className={styles.starIcons}>
              {starIndexes.map((i) => (
                <StarIcon
                  fill={i <= starCount ? "yellow" : "white"}
                  i={i}
                  setStarCount={setStarCount}
                  key={i}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.textareaContainer}>
          <textarea
            rows={5}
            className={styles.textarea}
            onChange={(e) => setFeedback(e.target.value)}
            value={feedback}
          ></textarea>
        </div>
        {message && (
          <span className={styles.message} style={{ color: messageColor }}>
            {message}
          </span>
        )}
      </div>
      <button
        onClick={async () => {
          if (customerName === "" || phone === "" || feedback === "") {
            setMessageColor("red");
            setMessage("Заповніть усі поля");
            return;
          }
          setMessage("");
          const data = {
            customerName,
            phone,
            starCount,
            feedback,
            date: new Date().toLocaleString().split(",")[0],
          };
          try {
            await publicAPI.addComment(data);
            setCustomerName("");
            setPhone("");
            setStarCount(0);
            setFeedback("");
            setMessageColor("green");
            setMessage(
              "Коментар отримано. Задля уникнення спаму він з'явиться не одразу"
            );
          } catch (e) {
            setMessageColor("red");
            setMessage("Упс, щось пішло не так :(");
            console.error(e);
          }
        }}
        className={styles.submitButton}
      >
        Додати відгук
      </button>
    </div>
  );
};

const StarIcon = ({ fill = "white", i = 0, setStarCount = () => {} }) => {
  return (
    <svg
      onClick={() => setStarCount(i)}
      width="18px"
      height="18px"
      version="1.1"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="star-icon"
    >
      <path
        fill={fill}
        stroke="black"
        strokeWidth="2"
        d="M11.985,18.136 L5.187,23.000 L7.536,14.988 L1.000,9.562 L8.950,9.493 L11.985,1.000 L14.936,9.562 L23.000,9.562 L16.433,15.040 L18.783,23.000 L11.985,18.136 L11.985,18.136 Z"
      />
    </svg>
  );
};

export default CreateFeedback;
