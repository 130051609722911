import styles from "../styles/Flag.module.css";

const Flag = ({ text = "" }) => {
  return (
    <div className={styles.flagContainer}>
      <svg
        className={styles.flagIcon}
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 100 100"
      >
        <rect width="100" height="100" fill="#ffa500" />
        <polygon points="0, 0 50, 50 0, 100" fill="#fff" />
      </svg>

      <span className={styles.flagText}>
        {text}
      </span>
    </div>
  );
};

export default Flag;
