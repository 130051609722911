import React, { useState } from "react";
import { publicAPI } from "../api/api";
import styles from "../styles/GetContacts.module.css";

const GetContacts = ({
  isOpen = true,
  cart = [],
  setIsOpen = () => {},
  setCartOn = () => {},
  setCart = () => {},
}) => {
  const [resultText, setResultText] = useState("");
  const [isComment, setIsComment] = useState(false);

  return (
    <div className={styles.container}>
      {isOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <svg
              onClick={() => {
                resultText === "" ? setIsOpen(false) : setCartOn(false);
              }}
              className={styles.closeButton}
              width="24px"
              height="24px"
              fill="none"
              viewBox="0 0 24 24"
              data-testid="SvgClose"
              data-qaid="SvgClose"
              focusable="false"
              aria-hidden="true"
              tabIndex="-1"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M4.414 3A1 1 0 0 0 3 4.414L10.586 12 3 19.586A1 1 0 1 0 4.414 21L12 13.414 19.586 21A1 1 0 0 0 21 19.586L13.414 12 21 4.414A1 1 0 0 0 19.586 3L12 10.586 4.414 3Z"
                clipRule="evenodd"
              ></path>
            </svg>
            {resultText === "" ? (
              <h2 className={styles.header}>Введіть контактні дані</h2>
            ) : (
              <div>
                {resultText.split("\n").map((text, index) => (
                  <span key={index} className={styles.resultText}>
                    {text}
                    <br />
                  </span>
                ))}
              </div>
            )}

            {resultText === "" && (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const name = e.currentTarget[0];
                  const phone = e.currentTarget[1];
                  const settlement = e.currentTarget[3];
                  const department = e.currentTarget[4];
                  let invalidInput = false;
                  if (name.value === "") {
                    name.placeholder = "Обов'язкове поле";
                    name.style.borderColor = "red";
                    invalidInput = true;
                  }
                  if (phone.value === "") {
                    phone.placeholder = "Обов'язкове поле";
                    phone.style.borderColor = "red";
                    invalidInput = true;
                  }
                  if (settlement.value === "") {
                    settlement.placeholder = "Обов'язкове поле";
                    settlement.style.borderColor = "red";
                    invalidInput = true;
                  }
                  if (department.value === "") {
                    department.placeholder = "Обов'язкове поле";
                    department.style.borderColor = "red";
                    invalidInput = true;
                  }
                  if (invalidInput) return;

                  const data = {
                    name: name.value,
                    phone: phone.value,
                    postalService: e.currentTarget[2].value,
                    settlement: settlement.value,
                    department: department.value,
                    paymentType: e.currentTarget[5].value,
                    comment: e.currentTarget[6].value,
                  };

                  try {
                    if (e.currentTarget[5].value === "now") {
                      const response = await publicAPI.paymentInit(
                        cart,
                        data,
                        "checkout=true"
                      );
                      setCart([]);
                      if (response?.data?.url)
                        window.open(response?.data?.url, "_self");
                    } else if (
                      e.currentTarget[5].value === "later" ||
                      e.currentTarget[5].value === "fop"
                    ) {
                      await publicAPI.paymentInit(cart, data, "checkout=false");
                      setCart([]);
                      setResultText("Дякуємо за покупку!\nОчікуйте на дзвінок");
                    }
                  } catch (err) {
                    setResultText("Упс, щось пішло не так :(");
                    console.log(err);
                  }
                }}
                className={styles.form}
              >
                <input
                  onFocus={(e) => {
                    e.target.style.borderColor = "#ccc";
                    e.target.placeholder = "ПІБ";
                  }}
                  type="text"
                  placeholder="ПІБ"
                  autoComplete="name"
                  className={styles.inputField}
                />
                <input
                  onFocus={(e) => {
                    e.target.style.borderColor = "#ccc";
                    e.target.placeholder = "Телефон";
                  }}
                  type="text"
                  placeholder="Телефон"
                  autoComplete="phone"
                  className={styles.inputField}
                />
                <select className={styles.inputField}>
                  <option value="Нова пошта">Нова пошта</option>
                </select>
                <input
                  onFocus={(e) => {
                    e.target.style.borderColor = "#ccc";
                    e.target.placeholder = "Населений пункт";
                  }}
                  type="text"
                  placeholder="Населений пункт"
                  autoComplete="address-level2"
                  className={styles.inputField}
                />
                <input
                  onFocus={(e) => {
                    e.target.style.borderColor = "#ccc";
                    e.target.placeholder = "Відділення";
                  }}
                  type="text"
                  placeholder="Відділення"
                  autoComplete="email"
                  className={styles.inputField}
                />
                <select className={styles.inputField}>
                  <option value="now">Оплата онлайн</option>
                  <option value="later">Оплата при отриманні</option>
                  <option value="fop">Оплата на рахунок ФОП</option>
                </select>
                {!isComment && (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      setIsComment(true);
                    }}
                    className={styles.addComment}
                  >
                    Додати коментар
                  </a>
                )}
                {isComment && (
                  <textarea
                    onFocus={(e) => {
                      e.target.style.borderColor = "#ccc";
                      e.target.placeholder = "Введіть коментар";
                    }}
                    type="text"
                    placeholder="Введіть коментар"
                    autoComplete="organization"
                    className={styles.inputField}
                  />
                )}
                <button type="submit" className={styles.submitButton}>
                  Оформити Замовлення
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GetContacts;
