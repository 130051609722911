import React, { useEffect, useState } from "react";
import s2 from "../styles/general/animations.module.css";
import styles from "../styles/Categories.module.css";
import { useLocation } from "react-router-dom";
import Description from "./description";
import { publicAPI } from "../api/api";
import HOST from "../env";
import useConditionalNavigate from "./custom_hooks/use_conditional_navigate";

const Categories = ({ setCategoryId = () => {} }) => {
  const [category, setCategory] = useState({});
  const location = useLocation().pathname.split("/");
  const _id = location[location.length - 1];
  const [path, setPath] = useState([]);
  const navigate = useConditionalNavigate();

  useEffect(() => {
    const getCategory = async () => {
      try {
        const res = await publicAPI.getCategory(_id);
        setCategory(res.data);
        setCategoryId(res.data._id || "");
        const path = await publicAPI.getCategory(_id, "path=true");
        if (path) setPath(path.data.path);
      } catch (err) {
        console.log(err);
      }
    };
    getCategory();
  }, [_id]);

  return (
    <div className={styles.categoriesContainer}>
      {path && (
        <div className={styles.pathContainer}>
          <div onClick={(e) => navigate("/", e)} className={styles.homeLink}>
            <svg
              className={styles.homeIcon}
              baseProfile="full"
              version="1.1"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m15.316708,6.684536l-6.592088,-6.225863c-0.196815,-0.148935 -0.39563,-0.379723 -0.646938,-0.379723l-0.105259,0c-0.251308,0 -0.450145,0.290621 -0.646915,0.439806l-6.655034,6.168643c-0.758855,0.622327 -0.775762,1.072473 -0.477278,1.433993c0.150685,0.182885 0.187566,0.280145 0.819165,0.280145l1.197615,-0.000341l0,5.967352c0,1.070859 0.535543,1.552478 1.387499,1.552478l1.417132,0l0.836867,-0.004999l0,-5.271193c0,-0.582673 0.332865,-0.757673 0.753469,-0.757673l2.884484,0c0.374973,0 0.702362,0.158002 0.702362,0.787465l0,5.234607l0.630303,0.011817l1.625173,0c0.897177,0 1.38759,-0.540134 1.38759,-1.464376l0,-6.058021l1.162688,-0.000205c0.588582,0 0.602284,0.016452 0.796441,-0.185521c0.395516,-0.411582 0.15732,-1.107468 -0.477278,-1.52839z"></path>
            </svg>
            <span className={styles.separator}>{">"}</span>
          </div>
          {path.map((part, i) => (
            <span
              key={i}
              onClick={(e) => navigate("/categories/" + part._id, e)}
              className={styles.pathPart}
            >
              {part.title} {i < path.length - 1 && ">"}
            </span>
          ))}
        </div>
      )}
      <Description
        data={_id ? category?.description : ""}
        captionText={category?.title}
        marginCaption={"0 0 20px 0"}
      ></Description>
      {category?.categories?.length > 0 && (
        <div className={styles.categoriesGrid}>
          {category?.categories?.map((category) => (
            <div key={category._id} className={styles.categoryWrapper}>
              <Category {...category}></Category>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const Category = ({
  _id = "",
  title = "",
  imageURL = "",
  isSmall = false,
}) => {
  const navigate = useConditionalNavigate();
  return (
    <div
      onClick={
        !isSmall
          ? (e) => {
              navigate("/categories/" + _id, e);
            }
          : () => {}
      }
      className={`${s2.vertigo} ${styles.category}`}
    >
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={`${HOST}/${imageURL}`}
          alt={title}
        ></img>
      </div>
      <div className={styles.titleContainer}>
        <span className="text_hover">{title}</span>
      </div>
    </div>
  );
};

export default Categories;