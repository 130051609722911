import React from "react";
import s from "../styles/Specs.module.css";

const Specs = ({ data = "" }) => {
  if (!data) return;
  return (
    <table className={s.specsTable}>
      <tbody>
        {data.split("\n\n").map((block, blockIndex) => (
          <React.Fragment key={blockIndex}>
            {block.split("\n").map((text, index) => {
              if (index === 0)
                return (
                  <tr key={index}>
                    <th className={s.specsHeader} colSpan="2">
                      {text}
                    </th>
                  </tr>
                );
              else
                return (
                  <tr key={index} className={s.specsRow}>
                    {text.split("\t").map((column, colIndex) => (
                      <td key={colIndex} className={s.specsCell}>
                        {column}
                      </td>
                    ))}
                  </tr>
                );
            })}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default Specs;
