import { useNavigate } from 'react-router-dom';

const useConditionalNavigate = () => {
  const navigate = useNavigate();

  const conditionalNavigate = (path, e) => {
    return (() => {
      if (e.ctrlKey || e.metaKey) {
        window.open(path, '_blank')
      } else {
        navigate(path);
      }
    })();
  };
  
  return conditionalNavigate;
};

export default useConditionalNavigate;